import React, { useEffect } from 'react'
import { GymJonesStyled } from './style'
import loading from '../../assests/Images/loading.png';
import backImg from '../../assests/Ninja icons/backFilled.svg';
import saved from '../../assests/Images/saved-fill.svg';
import gymJones from '../../assests/New Images/gymJones.svg';
import imagePreview from '../../assests/New Images/imagePreview.png';
import MyTabs from './MyTabs';
import HomeNavbar from '../home/HomeNavbar';
import Copyrights from '../home/Copyrights';
import { useNavigate } from 'react-router-dom';


const Index = () => {
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });

        }, 0);
    }, []);
    const moveBack = () => {
        navigate(-1);
    }
    const moveToPayment = () => {
        navigate('/payment-method');
    }
    return (
        <div>
            <div className="d-sm-block d-none">
                <HomeNavbar />
            </div>
            <GymJonesStyled>
                <div className="image">
                    <img src={imagePreview} className="img-fluid" alt="img-preview" />
                    <div className="backward d-md-none">
                        <img onClick={moveBack} src={backImg} alt="back" />
                    </div>
                    <div className="video-percentage">
                        <img src={loading} alt="loading" />
                    </div>
                    <div className="video-saved">
                        <img src={saved} alt="saved" />
                    </div>
                    <div className="profile-section">
                        <div className="profile d-flex">
                            <img src={gymJones} className="img-fluid" alt="" />
                            <div className="text-section  px-3">
                                <h4>Gym Jones</h4>
                                <h6>Kick Boxing</h6>
                            </div>
                        </div>
                        <div className="buttons pt-3">
                            <button className="btn bg-blue text-light">6W/4D</button>
                            <button className="btn btn-bg ms-2 text-light">$300.0</button>
                        </div>
                    </div>
                </div>
                <div className="container navigation pt-3 d-flex justify-content-end">
                    <button onClick={moveToPayment} className="btn  bg-danger mb-1 d-none d-sm-block">Start This Program</button>
                </div>
                <MyTabs />
                <div className="d-flex justify-content-center mt-3  container">
                    <button onClick={moveToPayment} className="btn  btn-bg w-75 mb-1 text-light  d-flex justify-content-center mt-2 d-sm-none">Start This
                        Program</button>
                </div>
            </GymJonesStyled>
            <div className="d-none d-sm-block">
            <Copyrights />
            </div>
        </div>
    )
}

export default Index