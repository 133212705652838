import React from "react";
import { WhoAreWeContainer } from "./style";
import partnerone from "../../assests/assets/body-img.png";
import whoareweone from "../../assests/assets/whoareweone.png";
import whoarewetwo from "../../assests/assets/whoarewetwo.png";
import { Container, Row, Col } from "react-bootstrap";

const Whoweare = () => {
  return (
    <WhoAreWeContainer>
      <Container>
        <Row>
          <Col lg={6} md={12} >
            <img className="whoareweImg" src={partnerone} alt="partnerone" />
          </Col>
          <Col lg={6} md={12}>
            <div className="whoareview-content">
              <h1>Who Are We</h1>
              <hr/>
              <p className="whoareview-content-firstpara" >
                Gymat an unknown printer took a galley of type and scraey aretea
                bled make a type specimen book. May has survived not onlyive
                centuries but also the leap electronic.
              </p>
              <p>
                Gymat an unknown printer took a galley of type and scraey aretea
                bled make a type specimen book. May has survived not onlyive
                centuries but also the leap electronic.
              </p>

              <div className="whoareview-content-first">
                <img src={whoareweone} alt="whoareweone" />
                <div className="whoareview-content-first-title">
                  <h2>Boxing</h2>
                  <p>Lorem ipsum dolor sit amet, consetetur.</p>
                </div>
              </div>
              <div className="whoareview-content-first">
                <img src={whoarewetwo} alt="whoarewetwo" />
                <div className="whoareview-content-first-title">
                  <h2>Karate</h2>
                  <p>Lorem ipsum dolor sit amet, consetetur.</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </WhoAreWeContainer>
  );
};

export default Whoweare;
