import React from "react";
import { LandingPageContainer } from "./style";
import HeroSection from "./HeroSection";
import Whoweare from "./Whoweare";
import OfferBody from "./OfferBody";
import Navbar from "./Navbar";
import Footer from "../footer/Footer";
import NinjaSlider from "./NinjaTrainingSlider";
import ReviewsFromYou from "./ReviewsFromYou";
import Trainers from "./Trainers";
import Home from '../home/Index'
const Index = () => {
  return (
    <div className="mainhomepage">
      <div className="web-view">
        <LandingPageContainer>
          <Navbar />
          <HeroSection />
          <Whoweare />
          <OfferBody />
          <Trainers />
          <NinjaSlider />
          <ReviewsFromYou />
          <Footer />
        </LandingPageContainer>
      </div>
      <div className="mobile-view">
        <Home/>
      </div>
    </div>
  );
};

export default Index;
