import React from 'react'
import { TrendingWorkoutStyled } from './style';
import { useNavigate } from 'react-router-dom';
import trainer1 from "../../assests/New Images/trainer1.png";
import trainer2 from "../../assests/New Images/trainer2.png";
import trainer3 from "../../assests/New Images/trainer3.png";
import trainer4 from "../../assests/New Images/trainer4.png";
import ic_logo_gym1 from "../../assests/Images/ic_logo_gym1.png";
import ic_logo_gym2 from "../../assests/Images/ic_logo_gym2.png";
import ic_maps from "../../assests/icons/ic_maps.svg";
import AliceCarousel from 'react-alice-carousel';

const TrendingWorkout = () => {
    const navigate = useNavigate();
    const moveToGymJones = () => {
        navigate('/gym-jones')
    }
    const workoutData = [
        {
            heroImg: trainer1,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Harrison Ford",
            description: "Kick Boxing",
        },
        {
            heroImg: trainer2,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Scarlett Johansson",
            description: "Taekwondo",
        },
        {
            heroImg: trainer3,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Jacked Street",
            description: "Kick Boxing",
        },
        {
            heroImg: trainer3,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Scarlett",
            description: "Taekwondo",
        },
        {
            heroImg: trainer4,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Jacked Street",
            description: "Kick Boxing",
        },
        {
            heroImg: trainer1,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Jacked Street",
            description: "Kick Boxing",
        },
        {
            heroImg: trainer2,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Scarlett Johansson",
            description: "Taekwondo",
        },
        {
            heroImg: trainer3,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Scarlett",
            description: "Taekwondo",
        },
    ];
    const workoutItems = [
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${workoutData[0].heroImg})`,
            }}
        >
            <div onClick={moveToGymJones} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${workoutData[0].heroImg})`,backgroundRepeat:'no-repeat',backgroundSize:'cover' }} >
                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-first">
                            <img className='mini-img' src={workoutData[0].img} alt="ic_logo_gym4" />
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial-content">
                            <h3>{workoutData[0].title}</h3>
                            <p className='mt-2'>{workoutData[0].description}</p>
                        </div>
                    </div>
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-first">
                            <img className='mini-img' src={workoutData[1].logo} alt="ic_logo_gym4" />
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial-content">
                            <h3>Private</h3>
                            <p className='mt-2'>2 week</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${workoutData[1].heroImg})`,
            }}
        >
            <div onClick={moveToGymJones} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${workoutData[1].heroImg})`,backgroundRepeat:'no-repeat',backgroundSize:'cover' }} >
                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-first">
                            <img className='mini-img' src={workoutData[1].img} alt="ic_logo_gym4" />
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial-content">
                            <h3>{workoutData[1].title}</h3>
                            <p className='mt-2'>{workoutData[1].description}</p>
                        </div>
                    </div>
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-first">
                            <img className='mini-img' src={workoutData[0].logo} alt="ic_logo_gym4" />
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial-content">
                            <h3>Private</h3>
                            <p className='mt-2'>2 week</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${workoutData[2].heroImg})`,backgroundRepeat:'no-repeat',backgroundSize:'cover'
            }}
        >
            <div onClick={moveToGymJones} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${workoutData[2].heroImg})` }} >
                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-first">
                            <img className='mini-img' src={workoutData[2].img} alt="ic_logo_gym4" />
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial-content">
                            <h3>{workoutData[2].title}</h3>
                            <p className='mt-2'>{workoutData[2].description}</p>
                        </div>
                    </div>
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-first">
                            <img className='mini-img' src={workoutData[2].logo} alt="ic_logo_gym4" />
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial-content">
                            <h3>Private</h3>
                            <p className='mt-2'>2 week</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        <div
        className="slider-trainer-card"
        style={{
            background: `linear-gradient(to bottom,  transparent , black), url(${workoutData[1].heroImg})`,
        }}
    >
        <div onClick={moveToGymJones} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${workoutData[1].heroImg})`,backgroundRepeat:'no-repeat',backgroundSize:'cover' }} >
            <div className="slider-trainer-card-images">
                <div className="slider-trainer-card-images-imgtestimonial">
                    <div className="slider-trainer-card-images-imgtestimonial-first">
                        <img className='mini-img' src={workoutData[1].img} alt="ic_logo_gym4" />
                    </div>
                    <div className="slider-trainer-card-images-imgtestimonial-content">
                        <h3>{workoutData[1].title}</h3>
                        <p className='mt-2'>{workoutData[1].description}</p>
                    </div>
                </div>
                <div className="slider-trainer-card-images-imgtestimonial">
                    <div className="slider-trainer-card-images-imgtestimonial-first">
                        <img className='mini-img' src={workoutData[0].logo} alt="ic_logo_gym4" />
                    </div>
                    <div className="slider-trainer-card-images-imgtestimonial-content">
                        <h3>Private</h3>
                        <p className='mt-2'>2 week</p>
                    </div>
                </div>
            </div>
        </div>
    </div>,
    <div
        className="slider-trainer-card"
        style={{
            background: `linear-gradient(to bottom,  transparent , black), url(${workoutData[2].heroImg})`,backgroundRepeat:'no-repeat',backgroundSize:'cover'
        }}
    >
        <div onClick={moveToGymJones} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${workoutData[2].heroImg})` }} >
            <div className="slider-trainer-card-images">
                <div className="slider-trainer-card-images-imgtestimonial">
                    <div className="slider-trainer-card-images-imgtestimonial-first">
                        <img className='mini-img' src={workoutData[2].img} alt="ic_logo_gym4" />
                    </div>
                    <div className="slider-trainer-card-images-imgtestimonial-content">
                        <h3>{workoutData[2].title}</h3>
                        <p className='mt-2'>{workoutData[2].description}</p>
                    </div>
                </div>
                <div className="slider-trainer-card-images-imgtestimonial">
                    <div className="slider-trainer-card-images-imgtestimonial-first">
                        <img className='mini-img' src={workoutData[2].logo} alt="ic_logo_gym4" />
                    </div>
                    <div className="slider-trainer-card-images-imgtestimonial-content">
                        <h3>Private</h3>
                        <p className='mt-2'>2 week</p>
                    </div>
                </div>
            </div>
        </div>
    </div>,

    ];
    const workoutResponsive = {
        0: { items: 1.1 },
        375: { items: 1.3 },
        800: { items: 2 },
        1024: { items: 3 },
        1400: { items: 3 },
    };;

    return (
        <TrendingWorkoutStyled>
            <div className="d-flex justify-content-between first-section align-items-center flex-wrap">
                <h5 className="heading text-start m-0">Trending Workout with Tools</h5>
                <div className="link-view">View All</div>
            </div>
            <div className="mt-4 trainer-slider-main">
                <AliceCarousel
                    mouseTracking
                    disableButtonsControls={
                        true
                    }
                    items={workoutItems}
                    responsive={
                        workoutResponsive
                    }
                    disableDotsControls={
                        true
                    }
                />
            </div>
        </TrendingWorkoutStyled>
    )
}

export default TrendingWorkout