import React from 'react'
import trainer1 from "../../assests/New Images/trainer1.png";
import trainer2 from "../../assests/New Images/trainer2.png";
import trainer3 from "../../assests/New Images/trainer3.png";
import trainer4 from "../../assests/New Images/trainer4.png";
import ic_logo_gym1 from "../../assests/Images/ic_logo_gym1.png";
import ic_maps from "../../assests/icons/ic_maps.svg";
import nextArrow from '../../assests/Ninja icons/ic_forward_arrow_with_circle.svg';
import prevArrow from '../../assests/Ninja icons/ic_back_arrow_with_circle.svg';
import { SessionStyledMain } from './style';
import { useNavigate } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
const Sessions = () => {
    const navigate = useNavigate();


    const NextArrowHandler = ({ isDisabled }) => <span style={{ opacity: isDisabled ? '0.5' : 1 }} className='d-none d-sm-inline'>
        <img className='prev-slide-img slide-img' src={prevArrow} alt="previous" />
    </span>
    const PrevArrowHandler = ({ isDisabled }) => <span style={{ opacity: isDisabled ? '0.5' : 1 }} className='d-none d-sm-inline'>
        <img className='next-slide-img slide-img' src={nextArrow} alt="next" />
    </span>
    const sessionsData = [
        {

            heroImg: trainer1,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Day 1",
            description: "Abs Training",
        },
        {

            heroImg: trainer2,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Day 2",
            description: "Abs Training",
        },
        {

            heroImg: trainer3,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Day 3",
            description: "Abs Training",
        },
        {

            heroImg: trainer4,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Day 4",
            description: "Abs Training",
        },
        {

            heroImg: trainer1,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Day 5",
            description: "Abs Training",
        },
        {

            heroImg: trainer2,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Day 6",
            description: "Abs Training",
        },
        {

            heroImg: trainer3,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Day 7",
            description: "Abs Training",
        },

    ];
    const moveToSession = () => {
        navigate('/sessions');
    }

    const mainTitles = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7'];
    const sessionItems = [
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${sessionsData[0].heroImg})`,
            }}
        >
            <div className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${sessionsData[0].heroImg})` }} onClick={moveToSession} >
                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                            <h3>{sessionsData[0].title}</h3>
                            <p className='mt-2'>{sessionsData[0].description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${sessionsData[1].heroImg})`,
            }}
        >
            <div className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${sessionsData[1].heroImg})` }} onClick={moveToSession} >
                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                            <h3>{sessionsData[1].title}</h3>
                            <p className='mt-2'>{sessionsData[1].description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${sessionsData[2].heroImg})`,
            }}
        >
            <div className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${sessionsData[2].heroImg})` }} onClick={moveToSession} >
                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                            <h3>{sessionsData[2].title}</h3>
                            <p className='mt-2'>{sessionsData[2].description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${sessionsData[3].heroImg})`,
            }}
        >
            <div className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${sessionsData[3].heroImg})` }} onClick={moveToSession} >
                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                            <h3>{sessionsData[3].title}</h3>
                            <p className='mt-2'>{sessionsData[3].description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${sessionsData[4].heroImg})`,
            }}
        >
            <div className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${sessionsData[4].heroImg})` }} onClick={moveToSession} >
                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                            <h3>{sessionsData[4].title}</h3>
                            <p className='mt-2'>{sessionsData[4].description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${sessionsData[5].heroImg})`,
            }}
        >
            <div className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${sessionsData[5].heroImg})` }} onClick={moveToSession} >
                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                            <h3>{sessionsData[5].title}</h3>
                            <p className='mt-2'>{sessionsData[5].description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${sessionsData[6].heroImg})`,
            }}
        >
            <div className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${sessionsData[6].heroImg})` }} onClick={moveToSession} >
                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                            <h3>{sessionsData[6].title}</h3>
                            <p className='mt-2'>{sessionsData[6].description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
    ];
    const sessionResponsive = {
        0: { items: 1.7 },
        370: { items: 1.5 },
        568: { items: 2.4 },
        768: { items: 3.3 },
        1024: { items: 4 },
    };
    return (
        <>

            {
                mainTitles.map((title, index) => (
                    <div key={index}>
                        <SessionStyledMain >
                            <h5 className="heading">{title}</h5>
                            <div className="trainer-slider-main">
                                <AliceCarousel
                                    mouseTracking
                                    disableButtonsControls={
                                        false
                                    }
                                    items={sessionItems}
                                    responsive={
                                        sessionResponsive
                                    }
                                    disableDotsControls={
                                        true
                                    }
                                    renderPrevButton={NextArrowHandler}
                                    renderNextButton={PrevArrowHandler}
                                />
                            </div>
                        </SessionStyledMain>
                    </div>
                ))
            }
        </>

    )
}

export default Sessions










