import React from "react";
import { HeroSectionContainer } from "./style";
import CustomButton from "../../Components/CustomButton/Index";
import { PrimaryColor } from "../../Components/GlobalStyle";

const HeroSection = () => {
  return (
    <HeroSectionContainer>
      <div className="container">
      <div className="hero-section-content">
        <h3>Nina Training</h3>
        <h1>Get Fit With Us</h1>
        <p>
          <span>Gymhen An Unknown Printer Took A Galley Of Type And</span>
          <span>Scrambled.It Has Survived Nknown Printercenturies.</span>
        </p>
        <CustomButton
          bgcolor={PrimaryColor}
          color="white"
          padding="11px 8px"
          width="35%"
          type="button"
          title="JOIN US NOW"
          margin='25px 0'
        />
      </div>
      </div>
    </HeroSectionContainer>
  );
};

export default HeroSection;
