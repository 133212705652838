import styled from 'styled-components';
import { PrimaryColor, secondaryGrey } from '../../Components/GlobalStyle';

export const WorkoutStyeld = styled.div`

.hero-section{
position: relative;
z-index: -1;
.content-section{
position:absolute;
bottom: 5%;
left: 4%;
.name{
font-size: 32px;
font-weight: 700;
line-height: 23px;
letter-spacing: 0em;
color: #FFFFFF;
}
.description{
font-size: 22px;
font-weight: 500;
line-height: 14px;
letter-spacing: 0em;
color: #FFFFFF;
margin-top: 17px;
}
.time-text{
background: ${PrimaryColor};
border-radius: 3px;
font-size: 14px;
font-weight: 500;
line-height: 9px;
letter-spacing: 0.02em;
color: #FFFFFF;
padding: 12px;
margin-top: 28px;
}
}
.img-fluid{
width :100vw;
@media screen and (max-width:480px){
height: 42vh !important;   
object-fit: cover;
background-position: center;
}
}
}
.video-pause{
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
img{
width:100px;
max-width: 75%;
}
}

.backward{
position: absolute;
top: 10%;
left: 10%;
}
.video-percentage{
position: absolute;
bottom: 5%;
right: 3%;
width: 60px;
img{
width: 85%;
}
}
@media screen and (max-width:480px) {
.content-section{
bottom: 10%;
}
.video-percentage{
bottom: 11%;
}
}
.video-saved{
position: absolute;
top: 10%;
left: 85%;
}
`
export const WorkoutListStyled = styled.div`
position: relative;
.rest-text{
color: ${secondaryGrey};
margin-top: 12px;
text-align: center;
&-clock{
margin-left: 8px;
margin-right: 8px;
}
}
.session-list-main{
@media screen and (max-width:480px) {
z-index: 20;
margin-top: -18px;
background-color: white;
border-radius: 32px 0;
}
.session-list{
    padding-top: 20px;

.slider-trainer-card {
height: 176px;
@media screen and (max-width:480px) {
height: 110px;
.side-img{
height: 52px;
}   
}
border-radius: 10px;
margin-top: 22px;
background-repeat: no-repeat !important;
background-position: center !important;
background-size: cover !important;
position: relative;
@media screen and (max-width:480px) {
margin-right: 7px;
}
.side-img{
position:absolute ;
right: 10%;
top:30%;    
height: 40px;
}
&-images {
display: flex;
justify-content: space-between;
align-items: flex-end;
height: 100%;
padding-bottom: 10px;

&-imgtestimonial {
display: flex;
align-items: center;

&-first {
img {
padding: 0 !important;
}
}
&-second {
img {
padding: 0 !important;
}
}

&-content {
h3 {
margin: 0;
color: #FFFFFF;
font-size: 18px;
font-weight: 700;
line-height: 14px;
letter-spacing: 0em;
margin-bottom: 14px;
}
p {
margin: 0;
font-size: 12px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0em;
color: white;
}
}
}
}
}
}
}


`

