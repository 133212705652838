import React from 'react'
import { Container } from 'react-bootstrap'
import Slider from 'react-slick/lib/slider';
import { TrainingLocationsMain } from './styleD';
import location1 from '../../assests/New Images/gym (1).png';
import location2 from '../../assests/New Images/gym (2).png';
import location3 from '../../assests/New Images/gym (3).png';
import location4 from '../../assests/New Images/gym (4).png';
import location5 from '../../assests/New Images/gym (5).png';

const TraningLocation = () => {

    const locationImgs=[location3,location4,location2,location1,location5]
    var settings = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: false,

                    dots: false,
                },
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 810,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,

                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,

                },
            },
        ],
    };
    return (
        <TrainingLocationsMain>
            <Container>
                <h5 className="heading">Ninja Training Locations</h5>
                <Slider {...settings} className="train-location-slider">
                    {locationImgs.map((img, index) => (
                        <div className='train-location-slider-card' key={index}>
                            <img src={img} alt="gym location px-2" />
                        </div>
                    ))}
                </Slider>
            </Container>
        </TrainingLocationsMain>
    )
}

export default TraningLocation