import React, { useState } from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import Details from './Details'
import Sessions from './Sessions'
import { MyTabsStyled } from './style'

const MyTabs = () => {
  const [isChange, setIsChange] = useState(false);
  return (
    <MyTabsStyled>
      <Container>
        <Tab.Container id="left-tabs-example" defaultActiveKey="details" onSelect={() => setIsChange(!isChange)}>
          <Row>
            <Nav variant="pills" className="flex-row gym-jones-tabs">
              <Nav.Item className='gym-jones-tabs-item'>
                <Nav.Link eventKey="details">Details</Nav.Link>
              </Nav.Item>
              <Nav.Item className='gym-jones-tabs-item'>
                <Nav.Link eventKey="sessions">Sessions</Nav.Link>
              </Nav.Item>
            </Nav>
            <Col>
              <Tab.Content>
                <Tab.Pane eventKey="details">
                  <Details />
                </Tab.Pane>
                <Tab.Pane eventKey="sessions" change={isChange}>
                  <Sessions />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </MyTabsStyled>
  )
}

export default MyTabs