import React, { useEffect } from 'react'
import { PaymentMethodStyled } from './style';
import tickIcon from '../../assests/Ninja icons/ic_tick.svg';
import { Input } from 'antd';
import { Col, Container, Row } from 'react-bootstrap';
import HomeNavbar from '../home/HomeNavbar';
import Copyrights from '../home/Copyrights';
import backFilled from '../../assests/Ninja icons/backFilled.svg'
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });

        }, 0);
    }, []);
    const moveBack = () => {
        navigate(-1);
    }

    return (
        <div style={{
            backgroundColor: 'white'
        }}>
            <div className="d-none d-sm-block">
                <HomeNavbar />
            </div>
            <PaymentMethodStyled>
                <Container>
                    <div className="toggle-section mt-4 d-flex justify-content-between  d-md-none align-items-center">
                        <img src={backFilled} onClick={moveBack} alt="back-filled " />
                        <p className='fw-bold mt-2 '>X</p>
                    </div>
                    <div className="inner-container">
                        <div className="payment-box d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <img src={tickIcon} alt="check" />
                                <div className="duration-section">
                                    <h6 className="title">This Hit Trainer Will improve Your back</h6>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="description">6 Week- 4 Days</p>
                                        <h5 className="price mt-2  d-sm-none">$500.00</h5>
                                    </div>
                                </div>
                            </div>
                            <h5 className="price d-none d-sm-block mb-3">$500.00</h5>
                        </div>
                        <p className="unlock-text">
                            Unlock the secret routines of top athletes with HD video & rep tracking
                        </p>
                        <div className="card-inputs">
                            <Input size="large" placeholder="Name On the Card" />
                            <Input size="large" placeholder="Card Number" />
                            <Row>
                                <Col xs={6}>
                                    <Input size="large" placeholder="MM/YY" />
                                </Col>
                                <Col xs={6}>
                                    <Input size="large" placeholder="Security Code" />
                                </Col>
                            </Row>
                            <Input size="large" placeholder="ZIP / Postal Code" />
                            <div className="complete-order d-flex justify-content-center">
                                <button className="complete-btn" onClick={moveBack}>Complete Order</button>
                            </div>
                        </div>
                    </div>
                </Container>
                <div className="d-none d-sm-block footer-section">
                    <Copyrights />
                </div>
            </PaymentMethodStyled>
        </div>
    )
}

export default Index