import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import LoginScreen from "./Screens/LoginScreen/Index";
import SignUpScreen from "./Screens/SignUpScreen/Index";
import Gender from "./Screens/Gender/Index";
import HomeD from "./Screens/home/IndexD";
import TrainingGoals from "./Screens/TrainingGoals/Index";
import Birthday from "./Screens/Birthday/Index";
import BodyType from "./Screens/BodyType/Index";
import ForgotScreen from "./Screens/ForgotScreen/Index";
import TrainerAbout from "./Screens/Trainer_About/Index";
import ProgramList from "./Screens/ProgramList/Index";
import ActivitionCode from "./Screens/ForgotScreen/ActivitionCode";
import ActivitionScreen from "./Screens/ForgotScreen/ActivitionScreen";
import SetNewPassword from "./Screens/SetNewPassword/Index";
import GetStarted from "./Screens/GetStarted/Index";
import Intro from "./Screens/intro/Intro";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./PrivateRoute";
import LandingPage from './Screens/LandingPage/Index';
import ImagePreview from "./Screens/ImagePreview/Index";
import HireMe from "./Screens/HireMe/Index";
import PaymentMethod from "./Screens/PaymentMethod/Index";
import VideoPlayer from "./Screens/VideoPlayer/Index";
import Sessions from "./Screens/Sessions/Index";
import WorkList from "./Screens/WorkoutList/Index";
import GymJones from "./Screens/GymJones/Index";

function App() {
  const [isLoggedIn, setisLoggedIn] = useState(true);

  const logIn = () => {
    setisLoggedIn(true);
  };
  // const logOut = () => {
  //   setisLoggedIn(false);
  // };
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  useEffect(() => {
    clearCacheData();
  }, [])

  return (
    <div className="ninja-main-container">
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<Intro />} />
        <Route path="/login" element={<LoginScreen logIn={logIn} />} />
        <Route path="/signup" element={<SignUpScreen />} />
        <Route path="/gender" element={<Gender />} />
        <Route path="/training-goals" element={<TrainingGoals />} />
        <Route path="/birth-date" element={<Birthday />} />
        <Route path="/body-type" element={<BodyType />} />
        <Route path="/my-home" element={<HomeD />} />
        <Route path="/trainer-about" element={<TrainerAbout />} />
        <Route path="/image-preview" element={<ImagePreview />} />
        <Route path="/program-List" element={<ProgramList />} />
        <Route path="/workout-list" element={<WorkList />} />
        <Route path="/sessions" element={<Sessions />} />

        <Route path="/video-player" element={<VideoPlayer />} />
        <Route path="/payment-method" element={<PaymentMethod />} />
        <Route path="/hire-me" element={<HireMe />} />
        <Route path="/gym-jones" element={<GymJones />} />

        <Route path="/forgot" element={<ForgotScreen />} />
        <Route path="/activition/:user" element={<ActivitionCode />} />
        <Route
          path="/activation-screen/:name/:path"
          element={<ActivitionScreen />}
        />
        <Route path="/new-password" element={<SetNewPassword />} />
        <Route path="/get-started" element={<GetStarted logIn={logIn} />} />
        <Route
          path="/home"
          element={
            <PrivateRoute isLoggedIn={isLoggedIn}>
              <LandingPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
