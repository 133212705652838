import React from "react";
import { NinjaTrainingSliderContainer } from "./style";
import cardimage from "../../assests/Images/ninja-card-image.png";
import Slider from "react-slick";

function NinjaTrainingSlider() {
  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 5,
    dots: false,
    centerMode: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <NinjaTrainingSliderContainer>
      <section className="ninjaslider-section">
        <div className="container">
          <div className="title-section">
            <h5> LOCATIONS </h5> <h1> Ninja Training Locations </h1> <hr />
          </div>
          <div className="cards">
            <div className="cards-inner">
              <Slider {...settings}  >
                <div className="card">
                  <img src={cardimage} />
                </div>
                <div className="card">
                  <img src={cardimage} />
                </div>
                <div className="card">
                  <img src={cardimage} />
                </div>
                <div className="card">
                  <img src={cardimage} />
                </div>
                <div className="card">
                  <img src={cardimage} />
                </div>
                <div className="card">
                  <img src={cardimage} />
                </div>
                <div className="card">
                  <img src={cardimage} />
                </div>
                <div className="card">
                  <img src={cardimage} />
                </div>
                <div className="card">
                  <img src={cardimage} />
                </div>
                <div className="card">
                  <img src={cardimage} />
                </div>
                {/* <div>
                  <h3>1</h3>
                </div>
                <div>
                  <h3>2</h3>
                </div>
                <div>
                  <h3>3</h3>
                </div>
                <div>
                  <h3>4</h3>
                </div>
                <div>
                  <h3>5</h3>
                </div>
                <div>
                  <h3>6</h3>
                </div>
                <div>
                  <h3>7</h3>
                </div>
                <div>
                  <h3>8</h3>
                </div> */}
                {/* <div className="card">
                  <img src={cardimage} />
                </div>
                <div className="card">
                  <img src={cardimage} />
                </div>
                <div className="card">
                  <img src={cardimage} />
                </div>
                <div className="card">
                  <img src={cardimage} />
                </div>
                <div className="card">
                  <img src={cardimage} />
                </div> */}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </NinjaTrainingSliderContainer>
  );
}

export default NinjaTrainingSlider;
