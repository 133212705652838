import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { TertiaryHeading } from '../../Components/GlobalStyle'
import { OurSpecialityStyled } from './style'

const OurSpeciality = () => {
    const specialityCards=[
        {
            title:"Boxing",
            sub_title:"Boxing is a combat sport in which two people, usually wearing protective gloves "
        },
        {
            title:"Karate",
            sub_title:"Boxing is a combat sport in which two people, usually wearing protective gloves "
        },
        {
            title:"Taekwondo",
            sub_title:"Taekwondo is a Korean form of martial arts characterized by punching and kicking."
        }
    ]
    return (
        <OurSpecialityStyled>
          
            <TertiaryHeading>
            Our Speciality
            </TertiaryHeading>
            <div className="cards-section">
                <Row className='justify-content-center'>
                    {specialityCards.map(({title,sub_title},index)=>(
                    <Col sm={6} md={4} className='mt-3 mt-md-0'>
                        <Card>
                            <h5 className="title">{title}</h5>
                            <div className="sub-title">{sub_title} </div>
                        </Card>
                    </Col>

                    ))}
                </Row>
                </div>
           
        </OurSpecialityStyled>
    )
}

export default OurSpeciality