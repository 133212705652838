import React, { useRef } from "react";
import { OfferBodyContainer } from "./style";
import { Container, Col, Row } from "react-bootstrap";
import jahanzaibdumble from "../../assests/assets/jahanzaibdumble.png";
import jahanzaibuilder from "../../assests/assets/jahanzaibuilder.png";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import arrowleft from "../../assests/Images/arrow-left.png";
import arrowright from "../../assests/Images/arrow-right.png";
import offerPerson from "../../assests/Images/offerPerson.png";
import star from "../../assests/Images/star.png";
import ic_product_gym3 from "../../assests/Images/ic_product_gym3.png";
import ic_product_gym4 from "../../assests/Images/ic_product_gym4.png";
import ic_logo_gym1 from "../../assests/Images/ic_logo_gym1.png";
import ic_logo_gym2 from "../../assests/Images/ic_logo_gym2.png";
import ic_maps from "../../assests/icons/ic_maps.svg";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const OfferBody = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <OfferBodyContainer>
      <Container>
        <img
          className="dumble-img"
          src={jahanzaibdumble}
          alt="jahanzaibdumble"
        />
        <img
          className="muscle-img"
          src={jahanzaibuilder}
          alt="jahanzaibuilder"
        />
        {/* <Row>
                <Col>
                    <div className='offer-body-content' >
                        <p>SERVICES</p>
                        <p>
                            <span>We Offer Body</span>
                            <span>Changes Classes</span>
                        </p>
                        <h6>Gymat an unknown printer took a galley of type and scraey aretea bled make a type specimen book. May has survived not onlyive </h6>
                    </div>
                </Col>
                <Col>Two</Col>
            </Row> */}
        <div className="Main-div">
          <div className="reviews-content">
            <h5> SERVICES </h5> <h1> We Offer Body Changes Classes</h1> <hr />
            <Swiper
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={1}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              <SwiperSlide>
                <p>
                  Gymat an unknown printer took a galley of type and scraey
                  aretea bled make a type specimen book. May has survived not
                  onlyive
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <p>
                  Gymat an unknown printer took a galley of type and scraey
                  aretea bled make a type specimen book. May has survived not
                  onlyive
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <p>
                  Gymat an unknown printer took a galley of type and scraey
                  aretea bled make a type specimen book. May has survived not
                  onlyive
                </p>
              </SwiperSlide>

              <div className="navigate-btns">
                <button ref={prevRef} className="navigate-btn" type="button">
                  <img src={arrowleft} />
                </button>
                <button
                  ref={nextRef}
                  className="navigate-btn disabled"
                  type="button"
                >
                  <img src={arrowright} />
                </button>
              </div>
            </Swiper>
          </div>
          <div className="reviews-image">
            <div className="offer-person-cards">
              <div
                className="slider-trainer-card"
                style={{
                  background: `linear-gradient(to bottom,  transparent , black), url(${offerPerson})`,
                }}
              >
                <div className="slider-trainer-card-images">
                  <div className="slider-trainer-card-images-imgtestimonial">
                    <div className="slider-trainer-card-images-imgtestimonial-content">
                      <h3>Jacked Street</h3>
                      <p>Kick Boxing</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="slider-trainer-card"
                style={{
                  background: `linear-gradient(to bottom,  transparent , black), url(${offerPerson})`,
                }}
              >
                <div className="slider-trainer-card-images">
                  <div className="slider-trainer-card-images-imgtestimonial">
                    <div className="slider-trainer-card-images-imgtestimonial-content">
                      <h3>Jacked Street</h3>
                      <p>Kick Boxing</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="offer-person-cards">
              <div
                className="slider-trainer-card"
                style={{
                  background: `linear-gradient(to bottom,  transparent , black), url(${offerPerson})`,
                }}
              >
                <div className="slider-trainer-card-images">
                  <div className="slider-trainer-card-images-imgtestimonial">
                    <div className="slider-trainer-card-images-imgtestimonial-content">
                      <h3>Jacked Street</h3>
                      <p>Kick Boxing</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="slider-trainer-card"
                style={{
                  background: `linear-gradient(to bottom,  transparent , black), url(${offerPerson})`,
                }}
              >
                <div className="slider-trainer-card-images">
                  <div className="slider-trainer-card-images-imgtestimonial">
                    <div className="slider-trainer-card-images-imgtestimonial-content">
                      <h3>Jacked Street</h3>
                      <p>Kick Boxing</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </OfferBodyContainer>
  );
};

export default OfferBody;
