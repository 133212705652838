import React from 'react'
import { Container } from 'react-bootstrap'
import { CopyrightsMainContainer } from './styleD'
import twitter from '../../assests/Ninja icons/ic_twitter.svg';
import linkedin from '../../assests/Ninja icons/ic_linkedin.svg';

const Copyrights = () => {
  return (
    <CopyrightsMainContainer>
        <Container>
        <div className="inner-section d-flex justify-content-between align-items-center flex-wrap mt-3 mt-sm-0 pb-2">
        <h6 className="title">© 2022 Copyright: Ninja Training</h6>
        <div className="icons-section d-flex ">
         <img src={twitter} alt="twitter" />
         <img src={linkedin} alt="twitter" />
         <img src={twitter} alt="twitter" />
        </div>
            </div>    
        </Container>
    </CopyrightsMainContainer>
  )
}

export default Copyrights