import styled from 'styled-components';
import {tertiaryDark, tertiaryGrey } from '../../Components/GlobalStyle';

export const HireMeStyled=styled.div`
.inner-container{
max-width:540px;
margin-top: 45px;
@media screen and (max-width:991px){
margin-top: 30px;  
}
}
.heading{
font-size: 24px;
font-weight: 500;
line-height: 29px;
letter-spacing: 0em;
color: ${tertiaryDark};
@media screen and (max-width:480px) {
font-size: 22px;
line-height: 24px;
    
}
}
.payment-boxes{
.payment-box{
padding:12px;
border: 1.5px solid #D6D6D6;
border-radius: 10px; 
margin-top: 24px;
img{
cursor:pointer;
}
.duration-section{
margin-left: 17px;
.title{
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
color: ${tertiaryDark};
margin: 0;
}
.description{
font-size: 14px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0em;
margin: 0;
color: #909090;
margin-top: 6px;
}    
}
.price{
font-size: 20px;
font-weight: 700;
line-height: 24px;
letter-spacing: 0em;
color: ${tertiaryDark};
margin: 0;
}

@media screen and (max-width:480px){
.duration-section{
margin-left: 17px;
.title{
font-size: 14px;
line-height: 18px;
}
.description{
font-size: 14px;
line-height: 17px;
}
}

.price{
font-size: 17px;
line-height: 20px;
}

}
}
}
.caution{
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
color: ${tertiaryGrey};
margin-top:24px;
}
.trail-button{
margin-top: 60px;
button{
background-color:#C7112B;
width: 100%;
color: #FFFFFF;
border-radius: 7px;
border:none;
}    
}
`