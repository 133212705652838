import styled from 'styled-components';
import banner from '../../assests/New Images/header.png'
export const HeroSectionMain=styled.div`
background-image:url(${banner});
height: 90vh;
background-repeat: no-repeat;
background-size: cover;
display: flex;
align-items: center;
@media screen and (max-width:480px) {
    height: 45vh;
}
.content-section{
margin-left: 160px;    
h3{
font-size: 54px;
font-weight: 700;
line-height: 64px;
letter-spacing: 0.02em;
color: #FFFFFF !important;
text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.6);
margin-top: 16px;
}
}
@media screen and (max-width:768px) {
justify-content:center;
.content-section{
margin-left: 30px;
h3{
font-size: 40px;
font-weight: 700;
line-height: 50px;
}

}
}
@media screen and (max-width:480px) {
justify-content:center;
.content-section{
margin-left: 20px;

h3{
font-size: 30px;
font-weight: 700;
line-height: 38px;
}

}
}
`

export const TrainersHomeMain=styled.div`
margin-top: 60px;
position: relative;

.slide-img{
width:40px;
height:40px;  
cursor:pointer;
}
.prev-slide-img{
position: absolute;
left:-5%;
top:50%;
z-index: 30;

}
.next-slide-img{
position: absolute;
right:-3%;
top:50%;
z-index: 30;

}

.heading{
font-family:'EnnVisionsBold';
font-size: 34px;
font-weight: 700;
line-height: 30px;
letter-spacing: 0em;
color: #1A1B1E;
text-align: center;  
}
.trainer-slider-main{
margin-top: 50px;
.slider-trainer-card {
height: 180px;
border-radius: 10px;
margin-right: 20px;

background-repeat: no-repeat !important;
background-position: center !important;
background-size: cover !important;


&-images {
display: flex;
justify-content: space-between;
align-items: flex-end;
height: 100%;

padding-bottom: 10px;

&-imgtestimonial {
display: flex;
align-items: center;

&-first {
img {
padding: 0 !important;
}
}
&-second {
img {
padding: 0 !important;
}
}

&-content {
h3 {
margin: 0;
color: white;
font-size: 13px;
font-weight: 500;
line-height: 13px;
letter-spacing: 0em;


}
p {
margin: 0;
color: white;
font-size: 11px;
}
}
}
}
}
}

`

export const TrainingLocationsMain=styled.div`
margin-top: 80px;
.heading{
font-family:'EnnVisionsBold';
font-size: 34px;
font-weight: 700;
line-height: 30px;
letter-spacing: 0em;
color: #1A1B1E;
text-align: center;  
}
.train-location-slider{
margin-top:50px;
&-card{
img{
border: 2px solid #BCBCBC;
border-radius: 20px;
margin-right: 10px;
}    
}
img{
/* width:170px; */
@media screen and (max-width:480px) {
width: 100%;       
}
width: 85%;
}    
}
@media screen and (max-width:480px){
.heading{   
font-size: 25px;
line-height:24px;   
} 
}
`
export const TrainingPrograms=styled.div`
margin-top: 60px;
position: relative;

.slide-img{
width:40px;
height:40px;  
cursor:pointer;
}
.prev-slide-img{
position: absolute;
left:-5%;
top:50%;
z-index: 30;

}
.next-slide-img{
position: absolute;
right:-3%;
top:50%;
z-index: 30;

}

.heading{
font-family:'EnnVisionsBold';
font-size: 34px;
font-weight: 700;
line-height: 30px;
letter-spacing: 0em;
color: #1A1B1E;
text-align: center;  
}
.trainer-slider-main{
margin-top: 50px;
.slider-trainer-card {
height: 250px;
border-radius: 10px;
margin-right: 20px;

background-repeat: no-repeat !important;
background-position: center !important;
background-size: cover !important;
@media screen and (max-width:480px) {
margin-right: 7px;
}

&-images {
display: flex;
justify-content: space-between;
align-items: flex-end;
height: 100%;

padding-bottom: 10px;

&-imgtestimonial {
display: flex;
align-items: center;

&-first {
img {
padding: 0 !important;
}
}
&-second {
img {
padding: 0 !important;
}
}

&-content {
h3 {
margin: 0;
color: white;
font-size: 13px;
font-weight: 500;
line-height: 13px;
letter-spacing: 0em;


}
p {
margin: 0;
color: white;
font-size: 11px;
}
}
}
}
}

}

`

export const CopyrightsMainContainer=styled.div`
background: #1A1B1E;
margin-top: 60px;
display: flex;
justify-content: space-between;
.inner-section{
h6{
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 48px;
letter-spacing: 0em;
text-align: left;
color: white;
}

}
.icons-section{
img{
height: 24px;

}    
img:not(:first-child){
margin-left:24px;
}    
}
`


export const StyledHomeNavbar=styled.div`
box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
.inner-container{
.img-container img{
height: 75px;
padding: 10px;

}    
.messages-icon{
margin-right:26px ;
} 
.profile-icon{
margin-right:10px ;
} 
.name{
margin-right:10px;
font-size: 16px;
font-weight: 500;
line-height: 10px;
letter-spacing: 0em;
}  
@media screen and (max-width:480px){
.img-container img{
height:65px;
}
.messages-icon{
margin-right:15px;    
}    
.name{
margin-right:5px;
}
}
}
`