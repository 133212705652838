import React from "react";
import { useNavigate } from "react-router-dom";
import { SliderContainer } from "./Style";
import AliceCarousel from "react-alice-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-alice-carousel/lib/alice-carousel.css';
const Index = ({ sliderImages, title }) => {
  const navigate = useNavigate();

  const navigateToTrainerAbout = () => {
    navigate('/trainer-about')
  }

  const locationItems = [
    <div className="slider-location-images">
      <img src={sliderImages[0]} alt="img" />
    </div>,
    <div className="slider-location-images">
      <img src={sliderImages[1]} alt="img" />
    </div>,
    <div className="slider-location-images">
      <img src={sliderImages[2]} alt="img" />
    </div>,
    <div className="slider-location-images">
      <img src={sliderImages[3]} alt="img" />
    </div>,
    <div className="slider-location-images">
      <img src={sliderImages[4]} alt="img" />
    </div>,
    <div className="slider-location-images">
      <img src={sliderImages[4]} alt="img" />
    </div>,
    <div className="slider-location-images">
      <img src={sliderImages[1]} alt="img" />
    </div>,
    <div className="slider-location-images">
      <img src={sliderImages[2]} alt="img" />
    </div>,
    <div className="slider-location-images">
      <img src={sliderImages[3]} alt="img" />
    </div>,
    <div className="slider-location-images">
      <img src={sliderImages[4]} alt="img" />
    </div>,
    <div className="slider-location-images">
      <img src={sliderImages[1]} alt="img" />
    </div>,
  ]
  const trainerItems = [
    <div className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${sliderImages[0].heroImg})` }} >
      <div className="slider-trainer-card-images" onClick={navigateToTrainerAbout}>
        <div className="slider-trainer-card-images-imgtestimonial">
          <div className="slider-trainer-card-images-imgtestimonial-first">
            <img src={sliderImages[0].img} alt="ic_logo_gym4" />
          </div>
          <div className="slider-trainer-card-images-imgtestimonial-content">
            <h3>Jacked Street</h3>
            <p>Kick Boxing</p>
          </div>
        </div>
        <div className="slider-trainer-card-images-imgtestimonial-second">
          <img src={sliderImages[0].logo} alt="ic_logo_gym4" />
        </div>
      </div>
    </div>,
    <div className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${sliderImages[1].heroImg})` }} >
      <div className="slider-trainer-card-images" onClick={navigateToTrainerAbout}>
        <div className="slider-trainer-card-images-imgtestimonial">
          <div className="slider-trainer-card-images-imgtestimonial-first">
            <img src={sliderImages[1].img} alt="ic_logo_gym4" />
          </div>
          <div className="slider-trainer-card-images-imgtestimonial-content">
            <h3>Jacked Street</h3>
            <p>Kick Boxing</p>
          </div>
        </div>
        <div className="slider-trainer-card-images-imgtestimonial-second">
          <img src={sliderImages[1].logo} alt="ic_logo_gym4" />
        </div>
      </div>
    </div>,
    <div className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${sliderImages[2].heroImg})` }} >
      <div className="slider-trainer-card-images" onClick={navigateToTrainerAbout}>
        <div className="slider-trainer-card-images-imgtestimonial">
          <div className="slider-trainer-card-images-imgtestimonial-first">
            <img src={sliderImages[2].img} alt="ic_logo_gym4" />
          </div>
          <div className="slider-trainer-card-images-imgtestimonial-content">
            <h3>Jacked Street</h3>
            <p>Kick Boxing</p>
          </div>
        </div>
        <div className="slider-trainer-card-images-imgtestimonial-second">
          <img src={sliderImages[2].logo} alt="ic_logo_gym4" />
        </div>
      </div>
    </div>,
  ]

  const trainersResponsive = {
    0: { items: 1.4 },
    568: { items: 2 },
    1024: { items: 2 },
  };
  const locationsResponsive = {
    0: {items: 2.6 },
    380:{items: 3.4 },
    568: { items: 3.4 },
    1024: { items: 4 },
  };

  return (
    <SliderContainer>
      {title === "location" ? (
        <AliceCarousel
          mouseTracking
          disableButtonsControls={
            true
          }
          items={locationItems}
          responsive={
            locationsResponsive
          }
          disableDotsControls={
            true
          }
        />
      ) : (
        <AliceCarousel
          mouseTracking
          disableButtonsControls={
            true
          }
          items={trainerItems}
          responsive={
            trainersResponsive
          }
          disableDotsControls={
            true
          }
        />
      )
      }
    </SliderContainer>
  )

};

export default Index;
