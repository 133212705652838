import React, { useEffect } from 'react'
import backImg from '../../assests/Ninja icons/backFilled.svg';
import saved from '../../assests/Images/saved-fill.svg';
import HomeNavbar from '../home/HomeNavbar';
import Copyrights from '../home/Copyrights';
import { WorkoutListStyled, WorkoutStyeld } from './style';
import loading from '../../assests/Images/loading.png';
import banner from '../../assests/Images/workout-banner.png';
// import play from '../../assests//Ninja icons/ic_play_icon_with_gradient.svg';
import trainProgram1 from "../../assests/New Images/trainProgram1.png";
import trainProgram2 from "../../assests/New Images/trainProgram2.png";
import trainProgram3 from "../../assests/New Images/trainProgram3.png";
import { TertiaryHeading } from '../../Components/GlobalStyle';
import { Col, Container, Row } from 'react-bootstrap';
import lockRound from '../../assests/Images/lock-round.png';
import playIcon from '../../assests/Ninja icons/ic_play_icon_with_gradient.svg';
import { useNavigate } from 'react-router-dom';
import { ClockCircleOutlined } from '@ant-design/icons';


const Index = () => {
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });

        }, 0);
    }, []);
    const moveBack = () => {
        navigate(-1)
    }
    const moveToGymJames = () => {
        navigate('/image-preview', { state: { title: 'video', address: '/sessions' } })
    }

    const heroSliderImages = [
        {
            heroImg: trainProgram3,
            title: "Abs Training",
            description: "05 Minutes - 36 Seconds",
        },
        {
            heroImg: trainProgram1,
            title: "Abs Training",
            description: "05 Minutes - 36 Seconds",
        },
        {
            heroImg: trainProgram2,
            title: "Abs Training",
            description: "05 Minutes - 36 Seconds",
        },
        {
            heroImg: trainProgram3,
            title: "Abs Training",
            description: "05 Minutes - 36 Seconds",
        }
    ];
    const heroSliderImages2 = [
        {
            heroImg: trainProgram2,
            title: "Abs Training",
            description: "05 Minutes - 36 Seconds",
        }
    ];
    const moveToPaymentSection = () => {
        navigate('/payment-method');
    }
    return (
        <div>
            <div className="d-sm-block d-none">
                <HomeNavbar />
            </div>
            <WorkoutStyeld>
                <WorkoutListStyled>
                    <div className='p-0 p-auto'>
                        <div className="hero-section">
                            <img onClick={moveToPaymentSection} src={banner} className="img-fluid" alt="preview" />
                            <div className="content-section">
                                <h4 className="name fs-3">Abs Training</h4>
                                <h6 className="description fs-5">Week1-day 1</h6>
                                <p className="time-text">3 Hours 23 Minutes</p>
                            </div>
                            {/* <div className="video-pause d-none d-sm-block">
                                <img src={play} alt="play-img" />
                            </div> */}
                            <div className="backward d-md-none">
                                <img onClick={moveBack} src={backImg} alt="saved" />
                            </div>
                            <div className="video-percentage">
                                <img src={loading} alt="loading" />
                            </div>
                            <div className="video-saved">
                                <img src={saved} alt="save" />
                            </div>
                        </div>
                    </div>
                    <div className="session-list-main">
                        <Container>
                            <div className="session-list">
                                <TertiaryHeading>
                                    Warm-Up
                                </TertiaryHeading>
                                <Row>
                                    {heroSliderImages.map((data, index) => (
                                        <Col ms={12} md={6}>
                                            <div key={index}>
                                                <div
                                                    className="slider-trainer-card"
                                                    style={{
                                                        background: `linear-gradient(to bottom,  transparent , black), url(${data.heroImg})`,
                                                    }}
                                                >
                                                    <div onClick={moveToGymJames} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${data.heroImg})` }}>
                                                        {
                                                            index % 2 !== 0 ? (
                                                                <img className='side-img' src={lockRound} alt="img" />
                                                            ) : (
                                                                <img className='side-img' src={playIcon} alt="img" />
                                                            )
                                                        }
                                                        <div className="slider-trainer-card-images">
                                                            <div className="slider-trainer-card-images-imgtestimonial">
                                                                <div className="ms-3 slider-trainer-card-images-imgtestimonial-content">
                                                                    <h3>{data.title}</h3>
                                                                    <p className='mt-2'>{data.description}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                            <div className="session-list">
                                <TertiaryHeading>
                                    Warm-Up
                                </TertiaryHeading>
                                <Row>
                                    {heroSliderImages2.map((data, index) => (
                                        <Col ms={12} md={6}>
                                            <div key={index}>
                                                <div onClick={moveToGymJames}
                                                    className="slider-trainer-card"
                                                    style={{
                                                        background: `linear-gradient(to bottom,  transparent , black), url(${data.heroImg})`,
                                                    }}
                                                >
                                                    <div className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${data.heroImg})` }}>
                                                        <img className='side-img' src={lockRound} alt="img" />
                                                        <div className="slider-trainer-card-images">
                                                            <div className="slider-trainer-card-images-imgtestimonial">
                                                                <div className="ms-3 slider-trainer-card-images-imgtestimonial-content">
                                                                    <h3>{data.title}</h3>
                                                                    <p className='mt-2'>{data.description}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                            <div className="rest-text d-flex justify-content-center align-items-center">Rest <div className='rest-text-clock mb-2'> <ClockCircleOutlined /></div> 25 Second</div>
                        </Container>
                    </div>
                </WorkoutListStyled>
            </WorkoutStyeld>
            <div className="d-none d-sm-block">
                <Copyrights />
            </div>
        </div >
    )
}

export default Index;























































