import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Checkbox } from "antd";
import { SignUpContainer } from "./styled";
import FormControl from "../../Components/FormControl";
import CustomButton from "../../Components/CustomButton/Index";
import { PrimaryColor } from "../../Components/GlobalStyle";
import { useNavigate } from "react-router-dom";
import ic_back from "../../assests/icons/ic_back.svg";
import ic_flag from "../../assests/icons/ic_flag.svg";
import ic_email from "../../assests/icons/ic_email.svg";
import ic_password from "../../assests/icons/ic_password.svg";
import GenericService from "../../Services/GenericService";
import { API_URL } from "../../Services/config";
import { toast } from "react-toastify";

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const initialValues = {
  // username: "",
  email: "",
  password: "",
  role: ["user"],
  phoneNumber: "",
};
const validationSchema = Yup.object({
  // username: Yup.string()
  //   .required("username is required!")
  //   .matches(/^(\S+$)/g, "username cannot contain blankspaces")
  //   .matches(/^[a-z][a-z0-9_.]*$/, "username must be lower case letter"),
  email: Yup.string()
    .email("Valid email address is required ")
    .required("Required"),
  password: Yup.string()
    .required("Required")
    .min(6, "Minimum six character is required"),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Must be 10 characters or more")
    .required("Required"),
});

const Index = () => {
  const navigation = useNavigate();
  const genericService = new GenericService();

  const nextScreenHandler = () => {
    navigation("/login");
  };

  const onSubmit = (value) => {
    const data = { ...value, phoneNumber: `+1${value.phoneNumber}` };

    genericService
      .post(`${API_URL}auth/signup`, data)
      .then((msg) => {
        if (msg.resultCode == 201) {
          localStorage.setItem("userData", JSON.stringify(value));

          toast(msg.message, "top-right");
          navigation("/activition/new");
        } else if (msg.resultCode == 500) {
          toast(msg.message, "top-right");
        }
      })
      .catch((error) => {
        if (error.message) {
          toast("Please complete your form attentially", "top-right");
        }
      });
  };

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  return (
    <div>
      <SignUpContainer>
        <div className="inputs-section d-flex flex-column justify-content-center">
          {/* <div className="signupScreen-logo">
          <img src={ic_logo} alt="ic_logo" />
        </div> */}
          <div className="signup-arrow">
            <img
              onClick={() => nextScreenHandler()}
              src={ic_back}
              alt="ic_back"
            />
          </div>
          <div className="signup-content">
            <p>Sign up for Ninja Training</p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form
                    name="basic"
                    onFinish={formik.handleSubmit}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    validateMessages={validationSchema}
                  >
                    <div className="login-input-fields">
                      {/* <div
                    className={
                      formik.errors.password && formik.touched.password
                        ? ""
                        : "login-input-fields-field"
                    }
                  >
                    <FormControl
                      control="input"
                      type="text"
                      name="username"
                      placeholder="Username"
                      className={
                        formik.errors.username && formik.touched.username
                          ? "is-invalid"
                          : "customInput"
                      }
                      prefix={<img src={ic_user} alt="ic_user" />}
                    />
                  </div> */}

                      <div
                        className={
                          formik.errors.password && formik.touched.password
                            ? ""
                            : "login-input-fields-field"
                        }
                      >
                        <FormControl
                          control="input"
                          type="text"
                          name="email"
                          placeholder="Email Address"
                          className={
                            formik.errors.email && formik.touched.email
                              ? "is-invalid"
                              : "customInput"
                          }
                          prefix={<img src={ic_email} alt="ic_user" />}
                        />
                      </div>

                      <div
                        className={
                          formik.errors.password && formik.touched.password
                            ? ""
                            : "login-input-fields-field"
                        }
                      >
                        <FormControl
                          control="input"
                          type="text"
                          name="phoneNumber"
                          maxLength="10"
                          placeholder="234-1234567"
                          className={
                            formik.errors.phoneNumber &&
                              formik.touched.phoneNumber
                              ? "is-invalid"
                              : "customInput"
                          }
                          prefix={<img src={ic_flag} alt="ic_user" />}
                        />
                      </div>

                      <div
                        className={
                          formik.errors.password && formik.touched.password
                            ? ""
                            : "login-input-fields-field"
                        }
                      >
                        <FormControl
                          control="password"
                          type="text"
                          name="password"
                          placeholder="Password"
                          className={
                            formik.errors.password && formik.touched.password
                              ? "is-invalid"
                              : "customInput"
                          }
                          prefix={<img src={ic_password} alt="ic_user" />}
                        />
                      </div>

                      <h6>
                        <Checkbox onChange={onChange}>
                          Agree to
                          <span className="signup-subcontent">
                            Terms of Use
                          </span>{" "}
                          and
                          <span className="signup-subcontent">
                            Privacy Policy
                          </span>{" "}
                        </Checkbox>
                      </h6>
                      <CustomButton
                        bgcolor={PrimaryColor}
                        color="white"
                        padding="11px 8px"
                        width="100%"
                        type="submit"
                        title="Sign Up"
                        clicked={() => navigation('/gender')}
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="authScreen-footer d-flex justify-content-center">
          <p onClick={() => nextScreenHandler()}>Already have account?</p>
        </div>
      </SignUpContainer>
    </div>
  );
};

export default Index;
