import React from 'react'
import { FooterContainer } from "./style";
import footerlogo from '../../assests/Images/footer-logo.png';
import location from '../../assests/Images/location.png';
import call from '../../assests/Images/call.png';

function footer() {
  return (
    <FooterContainer>
        <section className='footer-section'>
            <div className='container'>
                <div className='footer'>

                    <div className='logo-section'>
                        <img src={footerlogo}/>
                        <div className='item'>
                            <img src={location}/>
                            <p>463 Round Table Drive, Norwood, OH, Lorem ipso</p>
                        </div>
                        <div className='item'>
                            <img src={call}/>
                            <p>+123456789</p>
                        </div>
                    </div>

                    <div className='links'>
                        <label>Main Links</label>
                        <a href='#'>Home</a>
                        <a href='#'>About us</a>
                        <a href='#'>Shop</a>
                        <a href='#'>Contact Us</a>
                    </div>

                    <div className='links'>
                        <label>Account Links</label>
                        <a href='#'>Notifications</a>
                        <a href='#'>Terms & Conditions</a>
                        <a href='#'>Login</a>
                        <a href='#'>Trainers</a>
                    </div>

                    <div className='links'>
                        <label>Other Links</label>
                        <a href='#'>Notifications</a>
                        <a href='#'>Terms & Conditions</a>
                        <a href='#'>Login</a>
                        <a href='#'>Trainers</a>
                    </div>

                </div>
            </div>
            <div className='copyright'>
                All Copy Right 2022 @ Ninja Tariner
            </div>
        </section>
    </FooterContainer>
  )
}

export default footer