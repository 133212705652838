import React, { useEffect, useState } from 'react'
import { HireMeStyled } from './style';
import checkFilled from '../../assests/Ninja icons/radio-filled.svg';
import checkEmpty from '../../assests/Ninja icons/radio-empty.svg';
import { Container } from 'react-bootstrap';
import HomeNavbar from '../home/HomeNavbar';
import Copyrights from '../home/Copyrights';
import HeroSection from '../Trainer_About/HeroSection';
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const navigate = useNavigate();
    const [hireMe, setHireMe] = useState([
        { check: false, title: 'MONTHLY', sub_title: 'Billed Monthly', price: '$14.90/mo' },
        { check: true, title: 'YEARLY', sub_title: '$71.00 Billed Yearly', price: '$6.00/mo' },
        { check: false, title: 'QUATERLY', sub_title: '$26.99 Billed Every 3 mo.', price: '$9.00/mo' },
    ]);
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });

        }, 0);
    }, []);

    const handleChange = (index, check) => {
        hireMe[index].check = check;
        setHireMe([...hireMe]);
    }
    const moveToPayment = () => {
        navigate('/payment-method')
    }

    return (
        <div>
            <div className="d-none d-sm-block">
            <HomeNavbar />
            </div>
            <HeroSection />
            <HireMeStyled>
                <Container>
                    <div className="inner-container">
                        <h3 className="heading text-center text-sm-start">Your Trainer, Your Result</h3>
                        <div className="payment-boxes">
                            {
                                hireMe.map(({ title, sub_title, price, check }, index) => (
                                    <div key={index} className="payment-box d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            {
                                                check ? (<img src={checkFilled} onClick={() => handleChange(index, false)} alt="check" />) : (<img src={checkEmpty} onClick={() => handleChange(index, true)} alt="check" />)
                                            }
                                            <div className="duration-section">
                                                <h6 className="title">{title}</h6>
                                                <p className="description">{sub_title}</p>
                                            </div>
                                        </div>
                                        <h5 className="price">{price}</h5>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="trail-button d-none d-sm-block">
                            <button className='py-3' onClick={moveToPayment}>
                                Start 7 Days Free Trail
                            </button>
                        </div>
                        <p className="caution">Recurring billing. Cancel anytime.</p>
                    </div>
                    <div className="trail-button d-sm-none">
                        <button className='py-3' onClick={moveToPayment}>
                            Start 7 Days Free Trail
                        </button>
                    </div>
                </Container>
            </HireMeStyled>
            <div className="d-none d-sm-block">
            <Copyrights />
            </div>
        </div>
    )
}

export default Index