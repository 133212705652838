import React from 'react'
import banner from '../../assests/New Images/banner (1).png';
import lionIcon from '../../assests/Images/lion-icon.png'
import star from '../../assests/Ninja icons/ic_star.svg'
import { TrainerAboutHeroSectionMain } from './style'
import { Container } from 'react-bootstrap';
import message from '../../assests/Ninja icons/ic_message.svg';
import phone from '../../assests/Ninja icons/ic_call.svg';
import { useNavigate } from "react-router-dom";
import saveImg from '../../assests/Images/saved-fill.svg'
import backImg from '../../assests/Ninja icons/ic_back.svg'
import useMoveToTop from '../../Components/MoveToTop/useMoveToTop';

const HeroSection = () => {
    useMoveToTop();
    const navigate = useNavigate();
    const moveToHireMe = () => {
        navigate('/hire-me')
    }

    const moveBack = () => navigate(-1)

    return (
        <TrainerAboutHeroSectionMain>
            <div className="banner-img">
                <img src={banner} alt="banner" />
            </div>
            <div className="bottom-content-section">
                <Container>
                    <div className="inner-section d-flex justify-content-between flex-wrap">
                        <div className="intro-section">
                            <img className='gym-logo' src={lionIcon} alt="gym img" />
                            <img onClick={moveBack} src={backImg} className='back-img d-sm-none' alt="back-img" />
                            <img src={saveImg} className='save-img d-sm-none' alt="save-img" />
                            <div className='d-flex align-items-end d-sm-block align-self-sm-end'>
                                <div className='content-section'>
                                    <h6 className="name ">Jacked Street</h6>
                                    <div className="rating-section d-flex">
                                        <p className="description">Kick Boxing</p>
                                        <span>
                                            <img className='ms-2' src={star} alt="star" />
                                        </span>
                                        <p className="rating ms-2">(5)</p>
                                    </div>
                                </div>
                                <div className="contact-section contact-imgs d-flex d-sm-none ">
                                    <img src={message} alt="messge" />
                                    <img src={phone} alt="messge" />
                                </div>
                            </div>
                        </div>
                        <div className="contact-section d-sm-flex d-none align-items-end mt-2 mt-md-0 d-block d-sm-flex">
                            <img src={message} alt="messge" />
                            <img src={phone} alt="messge" />
                            <div className="hire-me d-none d-sm-block">
                                <button className='hire-me-btn ms-2' onClick={moveToHireMe}>Hire Me / $27 / mo</button>
                            </div>
                        </div>

                    </div>
                </Container>
            </div>
        </TrainerAboutHeroSectionMain>
    )
}

export default HeroSection