import React from 'react'
import OurGym from './OurGym'
import OurSpeciality from './OurSpeciality'
import TopTeam from './TopTeam'

const About = () => {
  return (
    <>
      <TopTeam />
      <OurSpeciality />
      <OurGym />
    </>
  )
}

export default About