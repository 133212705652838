import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Input } from "antd";
import { Container } from "./style";
import CustomButton from "../../Components/CustomButton/Index";
import { PrimaryColor } from "../../Components/GlobalStyle";
import { Link, useNavigate } from "react-router-dom";
import GenericService from "../../Services/GenericService";
import { API_URL } from "../../Services/config";
import { toast } from "react-toastify";
import ic_back from '../../assests/icons/ic_back.svg';
const initialValues = {
  email: "",
  password: "",
};
const validationSchema = Yup.object({
  email: Yup.string()
    .required("Email is required!")
    .email("Valid email address is required"),
  password: Yup.string()
    .required("Invalid credentials. Please try again!")
    .min(6, "Minimum six character is required"),
});

const heightTypes = ['in', 'cm'];
const weightTypes = ['lbs', 'kg'];
const Index = ({ logIn }) => {
  const navigation = useNavigate();
  const genericService = new GenericService();

  const prevScreenHandler = () => {
    navigation("/birth-date");
  };
  const onSubmit = (value) => {
    console.log(value, "value");
    genericService
      .post(`${API_URL}auth/signin`, value)
      .then((msg) => {
        if (msg.resultCode == 200) {
          logIn();
          localStorage.setItem("userData", JSON.stringify(msg.data));
          navigation("/home");
        } else {
          toast(msg.message, "top-right");
        }
      })
      .catch((error) => {
        console.log(error, "error");
        if (error.response.status == 401) {
          toast("login credentials is invalid", "top-right");
        }
      });

    // navigation("/home");
  };
  const handleChange = (value) => {
    document.getElementById('process-default-btn').style = null;
  }
  const onChange = (e) => {
    console.log(e);
  };

  return (
    <div style={{ height: '100vh' }} >
      <Container>
        <div className="page-indicator-section mt-3 d-flex align-items-center">
          <img onClick={prevScreenHandler} src={ic_back} alt="ic_back" />
          <div className="indicators  d-flex">
            <div className="indicator indicator-fill ms-3 mb-1" />
            <div className="indicator indicator-fill ms-3 mb-1" />
            <div className="indicator indicator-fill ms-3 mb-1" />
            <div className="indicator indicator-fill ms-3 mb-1" />
          </div>
        </div>
        <div className="body-type-intro">
          <h1>What is your body type?</h1>
          <h5 className="sub-title">Let us help find the best plan for you</h5>
        </div>
        <div className="body-type-content">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form
                  name="basic"
                  onFinish={formik.handleSubmit}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  validateMessages={validationSchema}
                >
                  <div className="body-type-buttons">
                    <div className="d-flex justify-content-between align-items-center">
                      <Input type='number' placeholder="Height" onChange={onChange} />
                      <div className="d-flex">

                        {
                          heightTypes.map((title, index) => (
                            <div className='type-btn d-flex' key={index}>
                              <input type="radio" id={title} name="brand" onClick={(e) => handleChange(e.target.value)} value={title} />
                              <label className="
                            ms-2" htmlFor={title}>{title}</label>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                  <div className="body-type-buttons">
                    <div className="d-flex justify-content-between align-items-center">
                      <Input type='number' placeholder="Weight" onChange={onChange} />
                      <div className="d-flex">
                        {
                          weightTypes.map((title, index) => (
                            <div className='type-btn d-flex' key={index}>
                              <input type="radio" id={title} name="brand" onClick={(e) => handleChange(e.target.value)} value={title} />
                              <label className="
                            ms-2" htmlFor={title}>{title}</label>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className="navigate-btn">
          <Link to='/get-started'>
            <CustomButton
              bgcolor={PrimaryColor}
              color="white"
              padding="11px 8px"
              width="100%"
              type="submit"
              title="Finish"
            />
          </Link>
          <div className="mt-2">
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Index;
