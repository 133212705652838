import styled from "styled-components";
import { PrimaryColor, secondaryDark } from "../../Components/GlobalStyle";

export const SignUpContainer = styled.div`
display: flex;
flex-direction: column;
padding: 15px 20px;
height: 100vh;
max-width: 380px;
margin-left: auto;
margin-right: auto;
background-color: white !important;

.inputs-section{
height: 60vh;
.signupScreen-logo{
margin-left: auto;
margin-right: auto;
text-align: center;
}


.login-input-fields{
&-field{
margin-bottom: 15px;
}
}

.signup-content {
p {
font-size: 24px;
font-weight: 700;
margin: 15px 0 !important;
font-family: 'EnnVisionsBold';
}

h6 {
font-size: 14px;
/* text-align: center; */
margin: 10px 0;
}
}

.signup-subcontent {
color: ${PrimaryColor};
cursor: pointer;
}



.signup-arrow{
display: flex;
cursor: pointer;
margin-top: 10px;


@media screen and (min-width : 768px) {
display: none;
}

img{
width: 35px;


}
}
}
.authScreen-footer{

p{
color: ${secondaryDark};
font-size: 17px;
margin-bottom: 20px !important;
cursor: pointer;
position: absolute;
bottom: 5%;
}
}
`;
