import styled from 'styled-components'
import ic_background_mobile from '../../assests/icons/ic_background.svg'
import background from '../../assests/Images/web-background.jpeg'

export const Wrapper = styled.div`
background-image: url(${ic_background_mobile});
background-position: center;
background-size: cover;
height: 100vh;
position: relative;


@media screen and (min-width : 480px) {
  background-image: url(${background}) !important;
}


.splash-screen-logo{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
}

`
