import styled from 'styled-components';

export const ProgramListStyled = styled.div`
.back-section{
margin-top: 30px;
img{
margin-bottom: 15px;
cursor: pointer;
}
.back-text{
font-size: 16px;
font-weight: 400;
line-height: 13px;
letter-spacing: 0em;
color: #C7112B;       
}
}
.product-cards{
margin-top:18px;
.list-card{
position:relative;
.main-img{
height:270px;
width: 100%;
object-fit: cover;
border-radius: 10px;
}
.feature-img{
height:24px;
}
.loading-img{
position:absolute;
left: 6%;
top: 4%;
width: 45px;
}
.feature-img1{
position:absolute;
right: 6%;
top: 6%;
}
.feature-img2{
position:absolute;
right: 15%;
top: 6%;
}
}
.content-section{
position: absolute;
bottom: 0;
width: 100%;
padding:0 10px;
.title{
font-size: 17px;
font-weight: 700;
line-height: 19px;
letter-spacing: 0em;
color: #FFFFFF;
        
}
.sub-title{
font-size: 12px;
font-weight: 700;
line-height: 15px;
letter-spacing: 0em;
color: #FFFFFF;
}
}
}

`