import React from 'react'
import { StyledHomeNavbar } from './styleD';
import messages from '../../assests/Ninja icons/ic_message_filled.svg';
import logo from '../../assests/icons/ic_header_logo.svg';
import moreIcon from '../../assests/icons/dropdown_bold.svg';
import profile from '../../assests/Images/profile.png';
import { Container } from 'react-bootstrap';


const HomeNavbar = () => {
  
  return (
    <StyledHomeNavbar>
        <Container>
        <div className="inner-container d-flex justify-content-between align-items-center">
            <div className="img-container mb-1">
                <img src={logo} alt="logo" />
            </div>
            <div className="right-section d-flex align-items-center">
                <img className='messages-icon mt-1' src={messages} alt="messages" />
                <img className='profile-icon'  src={profile} alt="profile" />
                <h5 className="name mt-2">John Smith</h5>
                <img className='more-icon' src={moreIcon} alt="more" />
            </div>
        </div>
        </Container>
    </StyledHomeNavbar>
  )
}

export default HomeNavbar