import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "antd";
import { LoginContainer } from "./styled";
import ic_logo from "../../assests/icons/ic_login_logo.svg";
import ic_user from "../../assests/icons/ic_user.svg";
import ic_password from "../../assests/icons/ic_password.svg";
import FormControl from "../../Components/FormControl";
import CustomButton from "../../Components/CustomButton/Index";
import { PrimaryColor } from "../../Components/GlobalStyle";
import { Link, useNavigate } from "react-router-dom";
import GenericService from "../../Services/GenericService";
import { API_URL } from "../../Services/config";
import { toast } from "react-toastify";
import ic_back from '../../assests/icons/ic_back.svg'

const initialValues = {
  email: "",
  password: "",
};
const validationSchema = Yup.object({
  email: Yup.string()
    .required("Email is required!")
    .email("Valid email address is required"),
  password: Yup.string()
    .required("Invalid credentials. Please try again!")
    .min(6, "Minimum six character is required"),
});

const Index = ({ logIn }) => {
  const navigate = useNavigate();
  const genericService = new GenericService();

  const nextScreenHandler = () => {
    navigate("/signup");
  };

  const onSubmit = (value) => {
    console.log(value, "value");
    navigate('/my-home');
    // genericService
    //   .post(`${API_URL}auth/signin`, value)
    //   .then((msg) => {
    //     if (msg.resultCode == 200) {
    //       logIn();
    //       localStorage.setItem("userData", JSON.stringify(msg.data));
    //       navigation("/my-home");
    //     } else {
    //       toast(msg.message, "top-right");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error, "error");
    //     if (error.response.status == 401) {
    //       toast("login credentials is invalid", "top-right");
    //     }
    //   });

    // navigation("/home");
  };

  return (
    <div>
      <LoginContainer>
        <div className="inputs-section d-flex flex-column justify-content-center">
          <div className="signup-arrow d-sm-none">
            <img onClick={() => nextScreenHandler()} src={ic_back} alt="ic_back" />
          </div>
          <div className="loginScreen-logo">
            <img src={ic_logo} alt="ic_logo" />
          </div>
          <div className="loginScreen-content">
            <h1>Sign in to Ninja</h1>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form
                    name="basic"
                    onFinish={formik.handleSubmit}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    validateMessages={validationSchema}
                  >
                    <div className="login-input-fields">
                      <div>
                        <FormControl
                          control="input"
                          type="text"
                          name="email"
                          placeholder="Enter Email"
                          className={
                            formik.errors.email && formik.touched.email
                              ? "is-invalid"
                              : "customInput"
                          }
                          prefix={<img src={ic_user} alt="ic_user" />}
                        />
                      </div>
                      <div className="login-input-fields-field">
                        <FormControl
                          control="password"
                          type="text"
                          name="password"
                          placeholder="Password"
                          className={
                            formik.errors.password && formik.touched.password
                              ? "is-invalid"
                              : "customInput"
                          }
                          prefix={<img src={ic_password} alt="ic_user" />}
                        />
                      </div>

                      <Link to="/forgot" className="text-decoration-none">Forgot Password</Link>
                      <CustomButton
                        bgcolor={PrimaryColor}
                        color="white"
                        padding="11px 8px"
                        width="100%"
                        type="submit"
                        title="Sign In"
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="authScreen-footer">
          <p onClick={() => nextScreenHandler()}>Create Account Now?</p>
        </div>
      </LoginContainer>
    </div>
  );
};

export default Index;
