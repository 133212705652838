import React from "react";
import { GetStartedContainer } from "./styles";
import Icon211 from "../../assests/icons/ic_logo.svg";
import CustomButton from "../../Components/CustomButton/Index";
import { PrimaryColor } from "../../Components/GlobalStyle";
import {useNavigate} from 'react-router-dom';

const Index = ({logIn}) => {

    const navigation = useNavigate()

    const navigateHandler = () => {
        logIn()
        navigation("/my-home");
    }
  return (
    <GetStartedContainer>
      <div className="getStarted-inner">
        <div className="getStarted-inner-content">
          <img src={Icon211} alt="Icon211" />
          <h1>Thank you for signing up for Ninja Training!</h1>
          <div className="getStarted-inner-content-para">
            <p>
              Congratulations, you have been selected to receive one free
              private training session to go along with your first year
              membership of Ninja Trainer.
            </p>
          </div>
        </div>
        <div className="getStarted-inner-button">
          <CustomButton
            bgcolor={PrimaryColor}
            color="white"
            padding="11px 8px"
            width="100%"
            type="button"
            title="Get Started"
            clicked={() => navigateHandler()}
          />
        </div>
      </div>
    </GetStartedContainer>
  );
};

export default Index;
