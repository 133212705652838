import React, { useState } from "react";
import { NavbarPageContainer } from "./style";
import logoimage from "../../assests/Images/ninja-nav-logo.png";
import phone from "../../assests/Images/phone.png";
import inbox from "../../assests/Images/inbox.png";
import fb from "../../assests/Images/facebook.png";
import insta from "../../assests/Images/insta.png";
import ytb from "../../assests/Images/youtube.png";
import google from "../../assests/Images/google+.png";
import search from "../../assests/Images/search.png";
import togglebars from "../../assests/Images/toggle.png";
import { Link } from "react-router-dom";
import MobileCanvas from '../mobileCanvas/Index'

const Navbar = () => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <MobileCanvas
        onClose={onClose}
        visible={visible}
        setVisible={setVisible}
      />
      <NavbarPageContainer>
        <section className="main-div">
          <div className="main-nav">
            <div className="logo">
              <img src={logoimage} />
            </div>
            <div className="nav-content">
              <div className="info-bar">
                <div className="contact">
                  <div className="item">
                    <img src={phone} />
                    740.993.7266
                  </div>
                  <div className="item">
                    <img src={inbox} />
                    info@gmail.com
                  </div>
                </div>

                <div className="social-icons">
                  <div className="item">
                    <a href="#">
                      <img src={fb} />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#">
                      <img src={ytb} />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#">
                      <img src={google} />
                    </a>
                  </div>
                  <div className="item">
                    <a href="#">
                      <img src={insta} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="navbar">
                <div className="nav-items">
                  <a href="#" className="nav-link active">
                    My Account
                  </a>
                  <a href="#" className="nav-link">
                    Subcription
                  </a>
                  <a href="#" className="nav-link">
                    Messages
                  </a>
                  <a href="#" className="nav-link">
                    Setting
                  </a>
                  <a href="#" className="nav-link">
                    Saved
                  </a>
                </div>
                <div className="icons">
                  <div className="">
                    <img src={search} />
                  </div>
                  {/* <div className='item toggle'>
                                <a href='#'>
                                    <img src={togglebars}/>
                                </a>
                            </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-nav">
            <div className="mobile-logo">
              <img src={logoimage} />
            </div>
            <div className="toggle-icon">
              <a onClick={() => setVisible(true)} href="#">
                <img src={togglebars} />
              </a>
            </div>
          </div>
        </section>
      </NavbarPageContainer>
    </div>
  );
};

export default Navbar;
