import React from "react";
import { Drawer } from "antd";
import { DrawerMainStyled, MobileDrawerContainer } from "./style";
import { Link } from "react-router-dom";
import ic_menu_details from "../../assests/Side menu icons/ic_menu_details.svg";
import ic_logo_gym3 from "../../assests/Images/ic_logo_gym3.png";
import ic_back from "../../assests/icons/ic_back.svg";
import ic_menu_account from "../../assests/Side menu icons/ic_menu_account.svg";
import ic_menu_message from "../../assests/Side menu icons/ic_menu_message.svg";
import ic_menu_settings from "../../assests/Side menu icons/ic_menu_settings.svg";
import ic_menu_subscriptions from "../../assests/Side menu icons/ic_menu_subscriptions.svg";
import logoImg from '../../assests/Ninja icons/ic_menu_logout.svg'
import saveImg from '../../assests/Ninja icons/ic_menu_saved.svg'
const Index = ({ onClose, visible, setVisible }) => {
  return (
    <DrawerMainStyled>
      <Drawer
      style={{
        padding: '0'
      }}
        title={
          <div className="mobile-nav-title">
            <img
              onClick={() => setVisible(false)}
              src={ic_back}
              alt="ic_back"
            />
            <div className="d-flex align-items-center">
              <img src={logoImg} alt="logout" />
              <p className="ms-2">Log out</p>
            </div>
          </div>
        }
        width={350}
        placement="left"
        onClose={onClose}
        visible={visible}
      >
        <MobileDrawerContainer>
          <div className="mobile-nav-profile-section">
            <div className="mobile-nav-profile-section-img">
              <img src={ic_logo_gym3} alt="ic_logo_gym3" />
            </div>
            <div className="mobile-nav-profile-section-content">
              <p>User Name</p>
              <span className="designation">Designation</span>
            </div>
          </div>
          <ul>
            <li>
              <Link to="#">
                <div className="mobile-nav-link">
                  <div className="mobile-nav-link-profile">
                    <img src={ic_menu_account} alt="ic_menu_account" />
                    <p>My Account</p>
                  </div>
                  <img src={ic_menu_details} alt="ic_menu_details" />
                </div>
              </Link>
              <Link to="#">
                <div className="mobile-nav-link">
                  <div className="mobile-nav-link-profile">
                    <img src={ic_menu_subscriptions} alt="ic_menu_subscriptions" />
                    <p>Subcriptions </p>
                  </div>
                  <img src={ic_menu_details} alt="ic_menu_details" />
                </div>
              </Link>
              <Link to="#">
                <div className="mobile-nav-link">
                  <div className="mobile-nav-link-profile">
                    <img src={ic_menu_message} alt="ic_menu_message" />
                    <p>Messages</p>
                  </div>
                  <img src={ic_menu_details} alt="ic_menu_details" />
                </div>
              </Link>
              <Link to="#">
                <div className="mobile-nav-link">
                  <div className="mobile-nav-link-profile">
                    <img src={ic_menu_settings} alt="ic_menu_settings" />
                    <p>Setting</p>
                  </div>
                  <img src={ic_menu_details} alt="ic_menu_details" />
                </div>
              </Link>
              <Link to="#">
                <div className="mobile-nav-link">
                  <div className="mobile-nav-link-profile">
                    <img src={saveImg} alt="ic_menu_saved" />
                    <p>Saved</p>
                  </div>
                  <img src={ic_menu_details} alt="ic_menu_details" />
                </div>
              </Link>

            </li>
          </ul>
        </MobileDrawerContainer>
      </Drawer>
    </DrawerMainStyled>
  );
};

export default Index;
