import styled from "styled-components";

export const InputStyleContainer = styled.div`
  .ant-input-password {
    background: white;
    border: 0.5px solid #d6d6e0;
    border-radius: 8px;
    height: 47px;
    width: 100%;
  }
  .is-invalid {
    border: 1px solid red;
    border-radius: 5px;
    background: white;
    border-radius: 8px;
    padding: 10px;
  }
  .ant-input-suffix span {
    font-size: 16px;
    font-weight: 600;
  }
  .ant-input-affix-wrapper.ant-input-password {
    padding: 0 10px;
  }
`;
