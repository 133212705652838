import React, { useEffect } from 'react'
import ic_header_logo from "../../assests/icons/ic_header_logo.svg";
import ic_menu from "../../assests/Ninja icons/ic_back.svg";
import { MobileNavStyled } from './style';
import { useNavigate } from 'react-router-dom';

const Index = () => {
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });

        }, 0);
    }, []);
    const moveBack = () => {
        navigate(-1);
    }

    return (
        <MobileNavStyled>
            <div className="home-screen-header">
                <img onClick={() => moveBack()} src={ic_menu} alt="ic_menu" className='mt-2' />
                <img className='mx-auto' src={ic_header_logo} alt="ic_header_logo" />
            </div>
        </MobileNavStyled>
    )
}

export default Index