import React from "react";
import { ForgotContainer } from "./style";
import { Link, useNavigate, useParams } from "react-router-dom";
import ic_back from "../../assests/icons/ic_back.svg";
import GenericService from "../../Services/GenericService";
import { API_URL } from "../../Services/config";
import ic_logo from "../../assests/icons/ic_login_logo.svg";

const Index = () => {
  const navigation = useNavigate();
  const genericService = new GenericService();

  const params = useParams()

  const username = localStorage.getItem("userData");

  const userData = username && JSON.parse(username);



  const nextScreenHandler = () => {
    navigation("/login");
  };

  const sendOtpHandler = (value) => {
    const data = {
      email: userData.email,
      channel: value,
    };
    genericService
      .post(`${API_URL}generateotp`, data)
      .then((msg) => {
        console.log(msg, "msg");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  return (
    <div style={{ height: '100vh' }} >
      <ForgotContainer>
        <div className="forgotScreen-logo">
          <img src={ic_logo} alt="ic_logo" />
        </div>
        <div className="forgot-arrow">
          <img onClick={() => nextScreenHandler()} src={ic_back} alt="ic_back" />
        </div>

        <div className="forgot-content">
          <h1>
            <span>Please verify your</span>
            <span>activation code.</span>
          </h1>
          <div className="forgot-content-activition-detail">
            <p>{userData?.email}</p>
            <Link to={`/activation-screen/email/${params.user}`} onClick={() => sendOtpHandler("email")}>
              Send Email
            </Link>
          </div>
          <div className="forgot-content-activition-detail">
            <p>{userData?.phoneNumber}</p>
            <Link
              to={`/activation-screen/phoneNumber/${params.user}`}
              onClick={() => sendOtpHandler("phoneNumber")}
            >
              Send Mobile
            </Link>
          </div>
        </div>
      </ForgotContainer>
    </div>
  );
};

export default Index;
