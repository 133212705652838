import styled from 'styled-components';
import { tertiaryDark, tertiaryGrewish } from '../../Components/GlobalStyle';

export const TrainerAboutHeroSectionMain = styled.div`

.banner-img{
img{
position: relative;
z-index: -1;
height: 40vh;
width:-webkit-fill-available;
object-fit: cover;
}
}
.bottom-content-section{
z-index:30;
margin-top: -34px;
}
.inner-section{
align-items: baseline;
.back-img{
position: absolute;
left: 5%;
top: 5%;
}
.save-img{
position: absolute;
right: 5%;
top: 5%;
}

.intro-section{
display: flex;
align-items: end;

.contact-imgs{
img{
height:50px;
margin-top: 30px;
margin-left: 10px;
}    
}
.gym-logo{
border: 1px solid #FFFFFF;
border-radius: 4px;  
width: 120px;
}    

.content-section{
margin-left: 20px;
}
.name{
font-size: 24px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
margin-top: 20px;
}
.rating-section{
margin-top: 15px;
.description{      
font-size: 20px;
font-weight: 500;
line-height: 22px;
letter-spacing: 0em;
margin: 0;
}
img{

} 
.rating{
margin:0;
}  
}

@media screen and (max-width:480px) {

.gym-logo{
width: 90px;
} 
.name{
font-size: 18px;
line-height: 17px;
margin-bottom: 7px;
}
.content-section{
margin-left:12px;
}
.description{    
font-size: 16px !important;
line-height: 14px;
}
}  
.rating-section{
margin-top:0;
}
}
.hire-me-btn{
background-color: #C7112B;
color: white;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
height: 42px;
max-width: 169px;
border-radius: 6px;
border: none;
padding: 10px;
} 
.contact-section{
img{
border: 1.5px solid #A9A9A9;
border-radius: 3.75px;  
padding:10px ;
margin-left:10px;
width: 45px;
height: 42px !important;
} 
}
}
`

export const MyTabsStyled = styled.div`
margin-top: 30px;

@media screen and (max-width:991px) {
margin-top: 20px;    
}
.nav{
border-bottom: 1px solid #8D99B2; 
.nav-item:last-child{
margin-left:50px;
}   
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
color: #1A1B1E;    
background:transparent;
border-bottom:3px solid #C7112B;
border-radius: 0;
}
.nav-link{
font-size: 18px;
font-weight: 500;
line-height: 12px;
letter-spacing: 0em;
color: #1A1B1E;
}
@media screen and (max-width:480px) {
.nav-item-styled{
width:50%;
}
.nav-link{
text-align: center;
}
.nav{
border-bottom: 1px solid #8D99B2; 
padding: 0;
.nav-item:last-child{
margin-left:0;
}   
} 
}

`
export const OurGymStyled = styled.div`
margin-top: 50px;
position: relative;
.slide-img{
width:40px;
height:40px;  
cursor:pointer;
}
.prev-slide-img{
position: absolute;
left:-5%;
top:50%;
z-index: 30;
}
.next-slide-img{
position: absolute;
right:-3%;
top:50%;
z-index: 30;
}
.heading{
font-family:'EnnVisionsBold';
font-size: 24px;
font-weight: 700;
line-height: 29px;
letter-spacing: 0em;
color: #1A1B1E;
}
.trainer-slider-main{
margin-top: 19px;
.slider-trainer-card {
height: 250px;
border-radius: 10px;
position:relative;
background-repeat: no-repeat !important;
background-position: center !important;
background-size: cover !important;
@media screen and (max-width:480px) {
margin-right: 7px;
}

&-images {
display: flex;
justify-content: space-between;
align-items: flex-end;
height: 100%;
padding-bottom: 10px;
.save-img{
position: absolute;
right: 6%;
top:8%;
}
.link-open{
position: absolute;
right: 6%;
bottom:4%;
color:white;
}
&-imgtestimonial {
display: flex;
align-items: center;

&-first {
img {
padding: 0 !important;
}
}
&-second {
img {
padding: 0 !important;
}
}

&-content {
h3 {
margin: 0;
color: #FFFFFF;
font-size: 18px;
font-weight: 700;
line-height: 14px;
letter-spacing: 0em;
margin-bottom: 14px;
}
p {
margin: 0;
font-size: 12px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0em;
color: white;
}
}
}
}
}
}
`

export const OurSpecialityStyled = styled.div`
margin-top: 50px;
.cards-section{
margin-top:30px;
.card{
padding: 16px;
border: 2px solid #BCBCBC;
border-radius: 8px;
text-align:center;
.title{
font-size: 22px;
font-weight: 500;
line-height: 26px;
letter-spacing: 0em;
color: #221E30;
}
.sub-title{
font-size: 16px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
color: #4D4D4D;    
}
}
}
`
export const TopTeamStyled = styled.div`
margin-top: 30px;
.sub-title{
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
color: #4D4D4D;
padding-bottom: 10px;
}
.hire-me-btn{
background-color: #C7112B;
color: white;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
height: 42px;
border-radius: 6px;
border: none;
}
@media screen and (max-width:480px) {
.sub-title{
border-bottom: 1px solid ${tertiaryGrewish};
}


}

.cards-section{
margin-top:35px; 
.card{
background: #FFFFFF;
box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
border-radius: 12px;    
.card-img{
position: relative;
}
.save-img{
position: absolute;
right: 5%;
top: 6%;
height: 28px;
}
.play-img{
position: absolute;
left: 50%;
top:36%;
transform: translate(-50%,-50%);
}
&-title{
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
color: #221E30;
margin-bottom: 4px;
}   
&-text{
font-size: 12px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0em;
color: #4D4D4D;
} 
}   
}
`

export const CoursesStyled = styled.div`
.head-section{
margin-top: 20px;
.view-link{
font-size: 16px;
font-weight: 500;
line-height: 10px;
letter-spacing: 0em;
color: ${tertiaryDark};
font-family:'EnnVisionsBold';

}    
}

.trainer-slider-main{
margin-top: 22px;
.slider-trainer-card {
height: 180px;
border-radius: 10px;
margin-right: 20px;
cursor: pinter;
background-repeat: no-repeat !important;
background-position: center !important;
background-size: cover !important;


&-images {
display: flex;
justify-content: space-between;
align-items: flex-end;
height: 100%;

padding-bottom: 10px;

&-imgtestimonial {
display: flex;
align-items: center;

&-first {
img {
padding: 0 !important;
}
}
&-second {
img {
padding: 0 !important;
}
}

&-content {
h5 {
margin: 0;
font-size: 15px;
font-weight: 700;
line-height: 16px;
letter-spacing: 0em;
color:white;
}
p {
margin: 0;
font-size: 13px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0em;
color:white;
}
@media screen and (max-width:480px){
h5 {
font-size: 12px;
line-height: 11px;
}
p {
font-size: 11px;
line-height: 14px;
}
}
}
}
}
}
}

.work-out-slides{
margin-top:22px;
.home-screen-programs{
display: grid;
gap: 30px;
grid-template-columns: repeat(4,1fr);    

@media screen and (max-width:991px){
gap: 20px;
grid-template-columns: repeat(3,1fr);  
}
@media screen and (max-width:480px){
gap: 16px;
grid-template-columns: repeat(2,1fr);   
}
&-program{
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;
margin: 15px 0;

&-img{
height: 170px;
background-position: center;
background-size: cover;
background-repeat: no-repeat;
border-radius: 10px;
}

&-content{
display: flex;
flex-direction: column;
justify-content: flex-end;
height: 100%;
padding: 12px;
h1{
margin: 0;
color: white;
font-size: 17px;
padding: 0;
font-weight: 700;
}
p{
margin: 0;
color: white;
font-size: 12px;
}
}
}
@media screen and (max-width:367px) {
&-program{
display: grid;
grid-template-columns: repeat(2,1fr);
gap: 9px;
&-img{
width: 135px;
height: 145px;
}
}
}    
  }
}
`