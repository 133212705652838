import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import trainer1 from "../../assests/New Images/trainer1.png";
import trainer2 from "../../assests/New Images/trainer2.png";
import trainer3 from "../../assests/New Images/trainer3.png";
import trainer4 from "../../assests/New Images/trainer4.png";
import ic_logo_gym1 from "../../assests/Images/ic_logo_gym1.png";
import ic_logo_gym2 from "../../assests/Images/ic_logo_gym2.png";
import ic_maps from "../../assests/icons/ic_maps.svg";
import nextArrow from '../../assests/Ninja icons/ic_forward_arrow_with_circle.svg';
import prevArrow from '../../assests/Ninja icons/ic_back_arrow_with_circle.svg';
import { Container } from 'react-bootstrap';
import { TrainersHomeMain } from './styleD';
import { useNavigate } from 'react-router-dom';
const Trainers = () => {
    const navigate = useNavigate();
    const navigateToTrainerAbout = () => {
        navigate('/trainer-about');
    }
    const NextArrowHandler = (props) => <span>
        <img onClick={props.onClick} className='prev-slide-img slide-img' src={prevArrow} alt="previous" />
    </span>
    const PrevArrowHandler = (props) => <span>
        <img onClick={props.onClick} className='next-slide-img slide-img' src={nextArrow} alt="next" />
    </span>
    const heroSliderImages = [
        {
            heroImg: trainer1,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Jacked Street",
            description: "Kick Boxing",
        },
        {
            heroImg: trainer2,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Scarlett Johansson",
            description: "Taekwondo",
        },
        {
            heroImg: trainer3,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Jacked Street",
            description: "Kick Boxing",
        },
        {
            heroImg: trainer3,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Scarlett",
            description: "Taekwondo",
        },
        {
            heroImg: trainer4,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Jacked Street",
            description: "Kick Boxing",
        },
        {
            heroImg: trainer1,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Jacked Street",
            description: "Kick Boxing",
        },
        {
            heroImg: trainer2,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Scarlett Johansson",
            description: "Taekwondo",
        },
        {
            heroImg: trainer3,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Scarlett",
            description: "Taekwondo",
        },
    ];
    var settings = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: false,
        nextArrow: <PrevArrowHandler />,
        prevArrow: <NextArrowHandler />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: false,

                    dots: false,
                },
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 810,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,

                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,

                },
            },
        ],
    };
    return (
        <TrainersHomeMain >

            <Container>
                <h5 className="heading">Trainers</h5>
                <Slider {...settings} className="trainer-slider-main">
                    {heroSliderImages.map((data, index) => (
                        <div key={index}>
                            <div
                                className="slider-trainer-card"
                                style={{
                                    background: `linear-gradient(to bottom,  transparent , black), url(${data.heroImg})`,
                                }}
                            >
                                <div onClick={navigateToTrainerAbout} className="slider-trainer-card"  style={{ background: `linear-gradient(to bottom,  transparent , black), url(${data.heroImg})` }} >
                                    {/* <img src={img} alt="img" /> */}
                                    <div className="slider-trainer-card-images">
                                        <div className="slider-trainer-card-images-imgtestimonial">
                                            <div className="slider-trainer-card-images-imgtestimonial-first">
                                                <img src={data.img} alt="ic_logo_gym4" />
                                            </div>
                                            <div className="slider-trainer-card-images-imgtestimonial-content">
                                                <h3>{data.title}</h3>
                                                <p className='mt-2'>{data.description}</p>
                                            </div>
                                        </div>
                                        <div className="slider-trainer-card-images-imgtestimonial-second ">
                                            <img src={data.logo} alt="ic_logo_gym4" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </Container >

        </TrainersHomeMain>

    )
}

export default Trainers










