import styled from "styled-components";
import ic_background_mobile from "../../assests/icons/ic_background.svg";
import background from '../../assests/Images/web-background.jpeg'



export const GetStartedContainer = styled.div`

  background-image: url(${background});
  background-position: center;
  background-size: cover;
  height: 100vh;
  position: relative;

  @media screen and (max-width : 480px) {
  background-image: url(${ic_background_mobile});
}

  .getStarted-inner {
    display: grid;
    grid-template-rows: 1.3fr 1fr;
    height: 100vh;

    max-width: 380px;
  margin-left: auto;
  margin-right: auto;


    &-content {
      align-self: flex-end;
        text-align: center;
      img {
        width: 50%;
        height: 50%;
        
      }
      &-para{
        max-width: 300px;
          margin-left: auto;
          margin-right: auto;
        p{
          color: white;
          text-align: center;
          line-height: 20px;
          font-size: 13px;
      }
      }
    
      h1{
          color: white;
          margin: 10px auto;
          max-width: 300px;
      }
    }

    &-button {
      align-self: flex-end;
      padding: 0 20px;
    }
  }
`;
