import styled from "styled-components";

export const Container = styled.div`
height: 100vh;
position: relative;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
padding: 15px;
 max-width: 375px;
border-radius: 5px;
background-color: white;
.body-type-intro{
position: absolute;
width: 90%;
top: 18%;

h1 {
font-size: 21px;
font-weight: 600;
padding: 8px 0;
font-family: "EnnVisionsMedium" !important;
margin-bottom:0;
}
.sub-title{
color: #AAB2BA;
font-size: 16px;
}
}
.body-type-content {
position: absolute;
width: 90%;
top: 35%;
}

.body-type-buttons{
 border:1px solid #C7112B;
 border-radius: 7px;
 padding: 9px;
 margin-top: 25px;
 .ant-input{
 border:none;
 box-shadow: none;
 }
.type-btn> label {
color:#363636;
font-style: normal;
font-weight: 400;
font-size: 14px;
color:white;
border-radius: 7px;
text-align: center;
cursor: pointer;
width: 50px!important;
height: 35px;
display: flex;
align-items: center;
justify-content: center;
}

.type-btn  input[type="radio"]+label{
border-radius:6px;
border:1px solid #DFE3EA;
font-weight: normal;
color:#221E30;
}
.type-btn  input[type="radio"]:checked+label { 
background:transparent;
color:white;
background-color:#C7112B;
}

.type-btn > input{
display: none;
}
}

.navigate-btn{
position: absolute;
width: 90%;
bottom: 0;
}
`;
