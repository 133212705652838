import styled from "styled-components";
import HeroImage from "../../assests/Images/heroImage.png";
import offerBodyBackground from "../../assests/Images/offerBodyBackground.png";

export const NavbarPageContainer = styled.div`
  .main-nav {
    height: 128px;
    width: 100%;
    /* max-width: 1230px; */
    margin: 0 auto;

    display: grid;
    grid-template-columns: 24% 1fr;

    @media screen and (max-width: 1400px) {
      height: 100px;
    }

    @media screen and (min-width: 1401px) {
      grid-template-columns: 350px 1fr;
    }
  }

  .logo {
    img {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 1400px) {
      height: 101px;
      object-fit: cover;
      img {
        width: 100%;
        height: 101px;
      }
    }

    @media screen and (min-width: 1401px) {
      height: 130px;
    }
  }

  .nav-content .info-bar {
    height: 48.05px;
    background: #111111;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1400px) {
      height: 40px;
    }
  }

  .nav-content .info-bar .contact {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .nav-content .info-bar .contact .item {
    display: flex;
    align-items: center;
    gap: 13px;

    font-family: "EnnVisions";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    height: 100%;
    min-width: 190px;
    border-right: 2px solid #2a2a2a;
    padding: 0 13px;
  }

  .nav-content .info-bar .social-icons {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .nav-content .info-bar .social-icons .item {
    width: 58px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    border-left: 2px solid #2a2a2a;
  }

  .nav-content .navbar {
    height: 80.08px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 1400px) {
      height: 60px;
    }
  }

  .nav-content .navbar .nav-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 70px;
    height: 80px;
    padding-right: 41px;

    @media screen and (max-width: 1400px) {
      height: 60px;
    }
  }

  .nav-content .navbar .nav-items .nav-link {
    font-family: "EnnVisions";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 0;
    color: #221e30;
  }

  .nav-content .navbar .nav-items .nav-link.active,
  .nav-content .navbar .nav-items .nav-link:hover {
    color: #c7112b;
  }

  .nav-content .navbar .icons {
    display: grid;
    grid-template-columns: 81px 81px;
    align-items: center;
    justify-items: center;
    border-left: 2px solid #eeeeee;
    height: 80.08px;
    @media screen and (max-width: 1400px) {
      height: 60px;
    }
  }

  .nav-content .navbar .icons .item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-content .navbar .icons .item:last-child {
    background: #c7112b;
  }

  .main-div .mobile-nav {
    height: 81px;
    width: 81px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #c7112b;
    display: none;
  }

  @media screen and (max-width: 1100px) {
    .main-nav {
      display: none;
    }

    .main-div .mobile-nav {
      display: block;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1000;
    }
    .main-div .mobile-logo {
      img {
        width: 60%;
      }
    }
    .main-div .toggle-icon {
      margin-right: 20px;
    }
  }
`;

export const LandingPageContainer = styled.div``;

export const HeroSectionContainer = styled.div`
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(${HeroImage});
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  .hero-section-content {
    position: absolute;
    top: 50%;
    left: 40%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-40%, -50%);
    z-index: 1;

    @media screen and (max-width: 991px) {
      width: 100%;
      padding: 0 10px;
    }

    &:before {
      content: "";
      position: absolute;
      left: -5%;
      top: 0;
      height: 100%;
      width: 6px;
      background: #c7112b;
      z-index: 1;
      border-radius: 15px;
    }

    h3 {
      color: white;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
    }
    h1 {
      color: white;
      font-family: "EnnVisionsBold" !important;
      font-weight: 700;
      font-size: 60px;
      line-height: 95px;
    }
    p {
      color: white;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      span {
        display: block;
      }
    }
  }

  /* transparancy: rgba(0, 0, 0, 0.5); */
`;

export const WhoAreWeContainer = styled.div`
  background-color: white;

  .whoareweImg {
    padding: 40px;
    width: 90%;
  }
  .whoareview-content {
    padding-top: 30px;
    h1 {
      font-family: "EnnVisionsBold" !important;
      font-weight: 700;
      font-size: 45px;
      line-height: 70px;
    }
    hr {
      height: 4px;
      width: 186px;
      background: #e41c26;
      border-radius: 10px;
      opacity: 1;
      margin: 0;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #6c6c6c;
    }
    &-firstpara {
      margin-top: 31px;
    }

    &-first {
      display: grid;
      grid-template-columns: 40px 1fr;
      gap: 30px;
      padding-bottom: 40px;
      align-items: center;
      h2 {
        font-weight: 900;
        font-size: 17px;
        line-height: 15px;
        color: #000000;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 15px;
        color: #7e7e7e;
      }
    }
  }
`;

export const OfferBodyContainer = styled.div`
  background-color: #f5f5f5;
  min-height: 772px;
  position: relative;
  overflow: hidden;

  .dumble-img {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    height: 348px;
    width: 367px;
  }

  .muscle-img {
    position: absolute;
    left: 15%;
    bottom: 0;
    z-index: 0;
    height: 282px;
    width: 384px;
  }

  .Main-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    padding: 100px 60px;
  }

  @media screen and (max-width: 1110px) {
    .Main-div {
      grid-template-columns: 1fr;
    }
  }
  .Main-div .reviews-content {
    overflow: hidden;
  }
  .Main-div .reviews-content h5 {
    font-family: "EnnVisions";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.07em;
    color: #5e5e5e;
    padding-top: 58px;
  }

  .Main-div .reviews-content h1 {
    font-family: "EnnVisionsBold";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 45px;

    color: #000000;
  }

  .Main-div .reviews-content hr {
    width: 186px;
    height: 4px;
    background: #e41c26;
    border-radius: 2px;
    opacity: 1;
  }

  .Main-div .reviews-content h4 {
    font-family: "EnnVisions";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.07em;
    padding-top: 30px;
    color: #7e7e7e;
  }

  .Main-div .reviews-content h3 {
    font-family: "EnnVisions";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.07em;
    padding-top: 11px;
    color: #000000;
  }

  .Main-div .reviews-content .rating {
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 12px 0;
  }

  .Main-div .reviews-content p {
    font-family: "EnnVisions";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    max-width: 400px;

    /* or 156% */

    color: #7e7e7e;
  }

  .Main-div .reviews-content .navigate-btns {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
  }

  .Main-div .reviews-content .navigate-btns .navigate-btn {
    height: 45px;
    width: 45px;
    background: #c7112b;
    padding: 8px;
    border-radius: 5px;
    border: none;
  }

  .Main-div .reviews-content .navigate-btns .navigate-btn.disabled {
    background: #221e30;
  }

  .Main-div .reviews-content .navigate-btns .navigate-btn img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }

  .Main-div .reviews-image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .slider-trainer-card {
    height: 180px;
    border-radius: 10px;

    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;

    &-images {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      height: 100%;
      padding: 0 10px;
      padding-bottom: 10px;

      &-imgtestimonial {
        display: flex;
        align-items: center;

        &-first {
          img {
            padding: 0 !important;
          }
        }
        &-second {
          img {
            padding: 0 !important;
          }
        }

        &-content {
          h3 {
            margin: 0;
            color: white;
            font-size: 13px;
          }
          p {
            margin: 0;
            color: white;
            font-size: 11px;
          }
        }
      }
    }
  }
  .Main-div .reviews-image {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    .slider-trainer-card {
      margin-top: 15px;
    }
  }
`;

export const NinjaTrainingSliderContainer = styled.div`
  background-color: white;
  .ninjaslider-section {
    min-height: 587px;
    background: #f5f5f5;
  }

  .ninjaslider-section .title-section {
    text-align: center;
    padding-top: 71px;
  }

  .ninjaslider-section .title-section h5 {
    font-family: "EnnVisions";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #5e5e5e;
  }

  .ninjaslider-section .title-section h1 {
    font-family: "EnnVisions";
    font-style: normal;
    font-weight: 700;
    font-size: 59px;
    line-height: 70px;
    color: #221e30;
    padding-bottom: 21px;
  }

  .ninjaslider-section .title-section hr {
    width: 186px;
    height: 2px;
    background: #c7112b;
    opacity: 1;
    margin: 0 auto;
  }

  .ninjaslider-section .cards {
    padding-top: 104px;
    padding-bottom: 107px;
  }

  .cards-inner .slick-slide > * {
    margin: 40px 10px !important;
  }

  .ninjaslider-section .cards .cards-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 36px;
  }
  .ninjaslider-section .cards .cards-inner .slick-slider {
    width: 100% !important;
    .slick-arrow {
      display: none !important;
    }
  }
  .ninjaslider-section .cards .card {
    height: 179px;
    /* width: min(200px, 90%); */
    border: none;
    /* filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161)); */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-radius: 10px;
    padding: 18px 0;
    width: unset;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .slick-slide {
    /* margin-right: 30px; */
  }
  .slick-track {
    min-width: 4343px !important;
  }
  .ninjaslider-section .cards .card img {
    height: 100%;
    object-fit: contain;
  }
`;

export const ReviewsFromYourContainer = styled.div`
  background: white;

  .Main-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    padding: 50px 0;
  }

  @media screen and (max-width: 1110px) {
    .Main-div {
      grid-template-columns: 1fr;
    }
  }
  .Main-div .reviews-content {
    overflow: hidden;
  }
  .Main-div .reviews-content h5 {
    font-family: "EnnVisions";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.07em;
    color: #5e5e5e;
    padding-top: 58px;
  }

  .Main-div .reviews-content h1 {
    font-family: "EnnVisionsBold";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 70px;

    color: #000000;
    max-width: 400px;
  }

  .Main-div .reviews-content hr {
    width: 186px;
    height: 4px;
    background: #e41c26;
    border-radius: 2px;
    opacity: 1;
  }

  .Main-div .reviews-content h4 {
    font-family: "EnnVisions";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.07em;
    padding-top: 30px;
    color: #7e7e7e;
  }

  .Main-div .reviews-content h3 {
    font-family: "EnnVisions";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.07em;
    padding-top: 11px;
    color: #000000;
  }

  .Main-div .reviews-content .rating {
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 12px 0;
  }

  .Main-div .reviews-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Main-div .reviews-image img {
    max-width: 450px;
  }

  .Main-div .reviews-content p {
    font-family: "EnnVisions";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */

    color: #7e7e7e;
  }

  .Main-div .reviews-content .navigate-btns {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .Main-div .reviews-content .navigate-btns .navigate-btn {
    height: 45px;
    width: 45px;
    background: #c7112b;
    padding: 8px;
    border-radius: 10px;
    border: none;
  }

  .Main-div .reviews-content .navigate-btns .navigate-btn.disabled {
    background: #221e30;
  }

  .Main-div .reviews-content .navigate-btns .navigate-btn img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
`;

export const TrainserContainer = styled.div`
  .tranier-slider-continer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const SliderContainer = styled.div`
  padding: 70px 0;
  overflow: hidden;
  .tranier-slider-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @media screen and (max-width: 780px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }

    &-content-detail {
      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #5e5e5e;
        margin: 0;
      }

      h1 {
        font-size: 60px;
        line-height: 72px;
        color: #221e30;
        margin: 0;
        font-family: "EnnVisionsBold" !important;
      }
      hr {
        height: 4px;
        width: 186px;
        background: #e41c26;
        border-radius: 10px;
        opacity: 1;
        margin: 0;
        margin-bottom: 20px;
      }
    }
  }

  .trainer-person {
    text-align: center;
    img {
      width: 70%;
    }
  }

  .slick-list {
    width: 45vw !important;
  }

  @media screen and (max-width: 800px) {
    .slick-list {
      width: 91vw !important;
    }
  }
  @media screen and (max-width: 600px) {
    .slick-list {
      width: 111vw !important;
    }
  }

  .trainer-persons-slider-main {
    display: flex;
    align-items: center;
  }

  .slider-trainer-card {
    height: 180px;
    border-radius: 10px;
    margin-right: 20px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    width: 290px;

    &-images {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      height: 100%;
      padding: 0 10px;
      padding-bottom: 10px;

      &-imgtestimonial {
        display: flex;
        align-items: center;

        &-first {
          img {
            padding: 0 !important;
          }
        }
        &-second {
          img {
            padding: 0 !important;
          }
        }

        &-content {
          h3 {
            margin: 0;
            color: white;
            font-size: 13px;
          }
          p {
            margin: 0;
            color: white;
            font-size: 11px;
          }
        }
      }
    }
  }
`;
