import React from 'react'
import Courses from './Courses'

const Program = () => {
  return (
    <>
    <Courses/>
    </>
  )
}

export default Program