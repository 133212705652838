import React from 'react'
import trainProgram1 from "../../assests/New Images/trainProgram1.png";
import trainProgram3 from "../../assests/New Images/trainProgram3.png";
import savedFill from "../../assests/Images/saved-fill.svg";
import { OurGymStyled } from './style';
import { Col, Row } from 'react-bootstrap';

const OurGym = () => {
    const gymsData = [
        {
            heroImg: trainProgram3,
            title: "BuiltRx Gym",
            description: "Los Angeles, CA 90095, USA",
        },
        {
            heroImg: trainProgram1,
            title: "BuiltRx Gym",
            description: "Los Angeles, CA 90095, USA",
        },
        
    ];

    return (
        <OurGymStyled>
            <h5 className="heading">Our Gym </h5>
            <Row className="trainer-slider-main">
                {gymsData.map((data, index) => (
                    <Col md={6} key={index} className='mt-2'>
                        <div
                            className="slider-trainer-card"
                            style={{
                                background: `linear-gradient(to bottom,  transparent , black), url(${data.heroImg})`,
                            }}
                        >
                            <div className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${data.heroImg})` }} >
                                <div className="slider-trainer-card-images">
                                    <div className="slider-trainer-card-images-imgtestimonial">
                                        <img className='save-img' src={savedFill} alt="save" />
                                        <div className="link-open fs-6 d-sm-none">Open</div>
                                        <div className="ms-3 slider-trainer-card-images-imgtestimonial-content">
                                            <h3>{data.title}</h3>
                                            <p className='mt-2'>{data.description}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </OurGymStyled>
    )
}

export default OurGym










