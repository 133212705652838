import React from 'react'
import MyTabs from './MyTabs'
import HeroSection from './HeroSection'
import HomeNavbar from '../home/HomeNavbar'
import Copyrights from '../home/Copyrights'
import useMoveToTop from '../../Components/MoveToTop/useMoveToTop'

const IndexD = () => {

  useMoveToTop();

  return (
    <div>
      <div className="d-none d-sm-block">
      <HomeNavbar />
      </div>
      <HeroSection />
      <MyTabs />
      <div className="d-none d-sm-block">
      <Copyrights />
      </div>
    </div>
  )
}

export default IndexD