import React , {useState} from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "antd";
import { ForgotContainer } from "./style";
import ic_user from "../../assests/icons/ic_user.svg";
import FormControl from "../../Components/FormControl";
import CustomButton from "../../Components/CustomButton/Index";
import { PrimaryColor } from "../../Components/GlobalStyle";
import { useNavigate } from "react-router-dom";
import ic_back from "../../assests/icons/ic_back.svg";
import GenericService from "../../Services/GenericService";
import { API_URL } from "../../Services/config";
// import ic_logo from "../../assests/icons/ic_login_logo.svg";


const initialValues = {
  email: "",
};
const validationSchema = Yup.object({
  email: Yup.string().required("Email is required!").email('Valid email is required'),
});

const Index = () => {
  const navigation = useNavigate();
  const genericService = new GenericService();


  const [error , setError] = useState('')

  const nextScreenHandler = () => {
    navigation("/login");
  };

  const onSubmit = (value) => {
    setError('')

    genericService
      .post(`${API_URL}getuseremailandphonenumber`, value)
      .then((msg) => {
        console.log(msg, 'msg');
        if(msg.resultCode == 200){
          localStorage.setItem('userData' , JSON.stringify(msg.data))
          navigation('/activition/forgot')
        }
        else if(msg.resultCode == 500){
          setError(msg.message)
        }
      })
      .catch((error) => {
        console.log(error, "error");
        setError(error.response.data.message);
      });

  };

  return (
    <div style={{height : '100vh'}} >
      <ForgotContainer>
      {/* <div className="forgotScreen-logo">
          <img src={ic_logo} alt="ic_logo" />
        </div> */}
      <div className="forgot-arrow">
        <img onClick={() => nextScreenHandler()} src={ic_back} alt="ic_back" />
      </div>
    
      <div className="forgot-content">
        <h1>Forgot Password?</h1>
        <p>Enter your username and we'll send you instructions on how to reset your password.</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form
                name="basic"
                onFinish={formik.handleSubmit}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                validateMessages={validationSchema}
              >
                <div className="login-input-fields">
                  <div>
                    <FormControl
                      control="input"
                      type="text"
                      name="email"
                      placeholder="Username"
                      className={formik.errors.email && formik.touched.email ?  'is-invalid': 'customInput'}
                      prefix={<img src={ic_user} alt='ic_user'/>}
                    />
                    <h4>{error}</h4>
                  </div>

                  
                  <CustomButton
                    bgcolor={PrimaryColor}
                    color="white"
                    padding="11px 8px"
                    width="100%"
                    type="submit"
                    title="Recover Password"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ForgotContainer>
    </div>
  );
};

export default Index;
