import styled from "styled-components";

export const Container = styled.div`
height: 100vh;
position: relative;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
padding: 15px;
 max-width: 375px;
border-radius: 5px;
background-color: white;
.body-type-intro{
position: absolute;
width: 90%;
top: 18%;

h1 {
font-size: 28px;
font-weight: 600;
padding: 8px 0;
font-family: "EnnVisionsMedium" !important;
margin-bottom:0;
}
.sub-title{
color: #AAB2BA;
font-size: 16px;
}
}
.body-type-content {
position: absolute;
width: 90%;
top: 45%;
}



.navigate-btns{
position: absolute;
width: 90%;
bottom: 0;
}
`;
