import React from 'react'
import { TertiaryHeading } from '../../Components/GlobalStyle'
import { CoursesStyled } from './style'
import trainer1 from "../../assests/New Images/trainer1.png";
import trainer2 from "../../assests/New Images/trainer2.png";
import trainer3 from "../../assests/New Images/trainer3.png";
import trainer4 from "../../assests/New Images/trainer4.png";
import ic_logo_gym1 from "../../assests/Images/ic_logo_gym1.png";
import ic_logo_gym2 from "../../assests/Images/ic_logo_gym2.png";
import ic_maps from "../../assests/icons/ic_maps.svg";
import ic_gym_banner1 from "../../assests/Images/ic_gym_banner1.png";
import { useNavigate } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
const Courses = () => {
    const navigate = useNavigate();
    const programSlideImages = [
        {
            heroImg: trainer1,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Kick Boxing & Self Defence",
            description: "45 Trainers",
        },
        {
            heroImg: trainer2,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Kick Boxing & Self Defence",
            description: "45 Trainers",
        },
        {
            heroImg: trainer3,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Kick Boxing & Self Defence",
            description: "45 Trainers",
        },
        {
            heroImg: trainer3,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Scarlett",
            description: "45 Trainers",
        },
        {
            heroImg: trainer4,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Kick Boxing & Self Defence",
            description: "45 Trainers",
        },
        {
            heroImg: trainer1,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Kick Boxing & Self Defence",
            description: "45 Trainers",
        },
        {
            heroImg: trainer2,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Kick Boxing & Self Defence",
            description: "45 Trainers",
        },
        {
            heroImg: trainer3,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Scarlett",
            description: "45 Trainers",
        },
    ];
    const workoutSlides = [
        {
            title: "Round House",
            description: "03 Videos",
        },
        {
            title: "Back Kick",
            description: "07 Videos",
        },
        {
            title: "Front Kick",
            description: "09 Videos",
        },
        {
            title: "Round House",
            description: "05 Videos",
        }
    ];

    const moveToSessions = () => {
        navigate('/gym-jones')
    }
    const moveToWorkoutList = () => {
        navigate('/workout-list');
    }
    const coursesItems = [
        <div>
            <div
                className="slider-trainer-card"
                style={{
                    background: `linear-gradient(to bottom,  transparent , black), url(${programSlideImages[0].heroImg})`,
                }}
            >
                <div onClick={moveToSessions} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${programSlideImages[0].heroImg})` }} >
                    <div className="slider-trainer-card-images">
                        <div className="slider-trainer-card-images-imgtestimonial">

                            <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                                <h5>{programSlideImages[0].title}</h5>
                                <p className='mt-2'>{programSlideImages[0].description}</p>
                            </div>
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial">
                            <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                                <p>Free</p>
                                <p className='mt-2'>5 Weeks 3 Days</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        <div>
            <div
                className="slider-trainer-card"
                style={{
                    background: `linear-gradient(to bottom,  transparent , black), url(${programSlideImages[1].heroImg})`,
                }}
            >
                <div onClick={moveToSessions} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${programSlideImages[1].heroImg})` }} >
                    <div className="slider-trainer-card-images">
                        <div className="slider-trainer-card-images-imgtestimonial">

                            <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                                <h5>{programSlideImages[1].title}</h5>
                                <p className='mt-2'>{programSlideImages[1].description}</p>
                            </div>
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial">

                            <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                                <p>Free</p>
                                <p className='mt-2'>5 Weeks 3 Days</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        <div>
            <div
                className="slider-trainer-card"
                style={{
                    background: `linear-gradient(to bottom,  transparent , black), url(${programSlideImages[2].heroImg})`,
                }}
            >
                <div onClick={moveToSessions} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${programSlideImages[2].heroImg})` }} >
                    <div className="slider-trainer-card-images">
                        <div className="slider-trainer-card-images-imgtestimonial">

                            <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                                <h5>{programSlideImages[2].title}</h5>
                                <p className='mt-2'>{programSlideImages[2].description}</p>
                            </div>
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial">

                            <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                                <p>Free</p>
                                <p className='mt-2'>5 Weeks 3 Days</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        <div>
            <div
                className="slider-trainer-card"
                style={{
                    background: `linear-gradient(to bottom,  transparent , black), url(${programSlideImages[2].heroImg})`,
                }}
            >
                <div onClick={moveToSessions} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${programSlideImages[2].heroImg})` }} >
                    <div className="slider-trainer-card-images">
                        <div className="slider-trainer-card-images-imgtestimonial">

                            <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                                <h5>{programSlideImages[2].title}</h5>
                                <p className='mt-2'>{programSlideImages[2].description}</p>
                            </div>
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial">

                            <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                                <p>Free</p>
                                <p className='mt-2'>5 Weeks 3 Days</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        <div>
            <div
                className="slider-trainer-card"
                style={{
                    background: `linear-gradient(to bottom,  transparent , black), url(${programSlideImages[2].heroImg})`,
                }}
            >
                <div onClick={moveToSessions} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${programSlideImages[2].heroImg})` }} >
                    <div className="slider-trainer-card-images">
                        <div className="slider-trainer-card-images-imgtestimonial">

                            <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                                <h5>{programSlideImages[2].title}</h5>
                                <p className='mt-2'>{programSlideImages[2].description}</p>
                            </div>
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial">

                            <div className="slider-trainer-card-images-imgtestimonial-content ms-3">
                                <p>Free</p>
                                <p className='mt-2'>5 Weeks 3 Days</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
    ]
    const programResponsive = {
        0: { items: 1.1 },
        380: { items: 1.3 },
        800: { items: 2 },
        1024: { items: 3 },
        1400: { items: 3 },
    };
    return (
        <CoursesStyled>
            <div className="head-section d-flex justify-content-between align-items-center">
                <TertiaryHeading>
                    Course for Beginner
                </TertiaryHeading>
                <p className="view-link m-0">View All</p>
            </div>
            <div className="courses-slides">
                <div className="trainer-slider-main">
                    <AliceCarousel
                        mouseTracking
                        disableButtonsControls={
                            true
                        }
                        items={coursesItems}
                        responsive={
                            programResponsive
                        }
                        disableDotsControls={
                            true
                        }
                    />
                </div>
            </div>
            <div className="mt-4">
                <TertiaryHeading>Workout With Jacked Street</TertiaryHeading>
            </div>
            <div className="work-out-slides">
                <div className="home-screen-programs">
                    {workoutSlides.map(({ title, description }, index) => (
                        <div onClick={moveToWorkoutList} key={index}
                            className="home-screen-programs-program-img"
                            style={{
                                background: `linear-gradient(to bottom,  transparent , black), url(${ic_gym_banner1})`,
                            }}
                        >
                            <div className="home-screen-programs-program-content">
                                <h1>{title}</h1>
                                <p>{description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </CoursesStyled>
    )
}

export default Courses