import React from 'react'
import { HeroSectionMain } from './styleD';
import logo from '../../assests/New Images/ic_header_logo.png';

const HeroSection = () => {
    return (
        <HeroSectionMain>
            <div className="content-section ">
                <div className="img-container">
                    <img src={logo} alt="logo" />
                </div>
                <h3>INCREASE YOUR
                    <br />
                    MUSCLE POWER</h3>
            </div>

        </HeroSectionMain>
    )
}

export default HeroSection