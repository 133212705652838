import React from 'react'
import HeroSection from './HeroSection'
import Trainers from './Trainers'
import TraningLocation from './TraningLocation'
import TrainingPrograms from './TrainingPrograms';
import Copyrights from './Copyrights';
import HomeNavbar from './HomeNavbar';
import Home from './Index';
import useMoveToTop from '../../Components/MoveToTop/useMoveToTop';

const IndexD = () => {

useMoveToTop();

  return (
    <>
    <div className="desktop-view d-none d-sm-block">
      <HomeNavbar />
      <HeroSection />
      <div className="trainer-section">
        <Trainers />
      </div>
      <TraningLocation />
      <TrainingPrograms />
      <Copyrights />
    </div>
      <div className="d-sm-none d-block">
        <Home/>
      </div>
    </>
  )
}

export default IndexD