import styled from 'styled-components';
import { tertiaryDark } from '../../Components/GlobalStyle';

export const WorklistMainStyled=styled.div`
.home-screen-header{
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px;
/* box-shadow: -3px 2px 18px -14px rgba(0,0,0,0.73);
-webkit-box-shadow: -3px 2px 18px -14px rgba(0,0,0,0.73);
-moz-box-shadow: -3px 2px 18px -14px rgba(0,0,0,0.73); */
}

`
export const WorkoutWithToolsStyled=styled.div`
margin-top: 30px;
position: relative;

.first-section{
.link-view{
color: ${tertiaryDark};
font-size: 16px;
font-weight: 500;
line-height: 10px;
letter-spacing: 0em;
font-family:'EnnVisionsBold';

}
.heading{
font-family:'EnnVisionsBold';
font-size: 30px;
font-weight: 700;
line-height: 30px;
letter-spacing: 0em;
color: #1A1B1E;
@media screen and (max-width:480px){
font-size: 20px;
line-height: 18px;
}
}
}
.slide-img{
width:40px;
height:40px;  
cursor:pointer;
}
.prev-slide-img{
position: absolute;
left:-5%;
top:50%;
z-index: 30;

}
.next-slide-img{
position: absolute;
right:-3%;
top:50%;
z-index: 30;

}


.trainer-slider-main{
margin-top: 20px;
display: grid;
column-gap: 12px;
row-gap: 12px;

@media screen and (min-width:781px) {
grid-template-columns: repeat(3,1fr);
}
@media screen and (min-width:991px) {
grid-template-columns: repeat(4,1fr);
}
@media screen and (max-width:780px) {
grid-template-columns: repeat(2,1fr);
}

.slider-trainer-card {
height: 180px;
border-radius: 10px;
background-repeat: no-repeat !important;
background-position: center !important;
background-size: cover !important;

&-images {
display: flex;
justify-content: space-between;
align-items: flex-end;
height: 100%;

padding-bottom: 10px;

&-imgtestimonial {
display: flex;
align-items: center;

&-first {
img {
padding: 0 !important;
}
}
&-second {
img {
padding: 0 !important;
}
}

&-content {
h3 {
margin: 0;
color: white;
font-size: 14px;
font-weight: 700;
line-height: 11px;
letter-spacing: 0em;


}
p {
margin: 0;
color: white;
font-size: 12px;
}
}
}
}
}

}
`

export const WorkoutWithoutToolsStyled=styled.div`
margin-top: 30px;
position: relative;

.first-section{
.link-view{
color: ${tertiaryDark};
font-size: 16px;
font-weight: 500;
line-height: 10px;
letter-spacing: 0em;
font-family:'EnnVisionsBold';

}
.heading{
font-family:'EnnVisionsBold';
font-size: 30px;
font-weight: 700;
line-height: 30px;
letter-spacing: 0em;
color: #1A1B1E;
@media screen and (max-width:480px){
font-size: 20px;
line-height: 18px;
}
}
}
.slide-img{
width:40px;
height:40px;  
cursor:pointer;
}
.prev-slide-img{
position: absolute;
left:-5%;
top:50%;
z-index: 30;

}
.next-slide-img{
position: absolute;
right:-3%;
top:50%;
z-index: 30;

}


.trainer-slider-main{
margin-top: 20px;
.slider-trainer-card {
height: 180px;
border-radius: 10px;
margin-right: 20px;
background-repeat: no-repeat !important;
background-position: center !important;
background-size: cover !important;

&-images {
display: flex;
justify-content: space-between;
align-items: flex-end;
height: 100%;

padding-bottom: 10px;

&-imgtestimonial {
display: flex;
align-items: center;

&-first {
img {
padding: 0 !important;
}
}
&-second {
img {
padding: 0 !important;
}
}

&-content {
h3 {
margin: 0;
color: white;
font-size: 14px;
font-weight: 700;
line-height: 11px;
letter-spacing: 0em;


}
p {
margin: 0;
color: white;
font-size: 12px;
}
}
}
}
}

}
`

export const TrendingWorkoutStyled=styled.div`
margin-top: 40px;
position: relative;
.first-section{
.link-view{
color: ${tertiaryDark};
font-size: 16px;
font-weight: 500;
line-height: 10px;
font-family:'EnnVisionsBold';
letter-spacing: 0em;
}
.heading{
font-family:'EnnVisionsBold';
font-size: 30px;
font-weight: 700;
line-height: 30px;
letter-spacing: 0em;
color: #1A1B1E;
@media screen and (max-width:480px){
font-size: 20px;
line-height: 18px;
}
}
}
.trainer-slider-main{
margin-top: 50px;
.mini-img{
  width:50px;
  height:50px;
}
.slider-trainer-card {
height: 180px;
border-radius: 10px;
margin-right: 20px;

background-repeat: no-repeat !important;
background-position: center !important;
background-size: cover !important;


&-images {
display: flex;
justify-content: space-between;
align-items: flex-end;
height: 100%;

padding-bottom: 10px;

&-imgtestimonial {
display: flex;
align-items: center;

&-first {
img {
padding: 0 !important;
}
}
&-second {
img {
padding: 0 !important;
}
}

&-content {
h3 {
margin: 0;
color: white;
font-size: 14px;
font-weight: 700;
line-height: 11px;
letter-spacing: 0em;


}
p {
margin: 0;
color: white;
font-size: 12px;
}
}
}
}
}

}
`