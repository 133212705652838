import React, { useEffect } from "react";
import { Wrapper } from "./style";
import ic_logo from "../../assests/icons/ic_logo.svg";
import { useNavigate } from "react-router-dom";

function Intro() {
  const navigation = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigation("/login");
    }, 2000);
  }, []);

  return (
    <Wrapper>
      <div className="splash-screen-logo" >
        <img src={ic_logo} alt='ic_logo' />
      </div>
    </Wrapper>
  );
}

export default Intro;
