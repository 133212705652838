import styled from "styled-components";
import { PrimaryColor } from "../../Components/GlobalStyle";

export const ForgotContainer = styled.div`
box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  @media screen and (min-width: 768px) {
    max-width: 460px;
    background-color: white !important;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
  
  }
  @media screen and (max-width: 768px) {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    background-color: white !important;
    height: 100vh;

  }

  .forgotScreen-logo{
    text-align: center;
    margin: 20px auto;
  }
  .forgot-arrow {
    cursor: pointer;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .forgot-content {
    h1 {
      color: #1a1b1e;
      font-weight: 600;
      font-size: 29px;
      line-height: 37px;
      margin-bottom: 30px;

      span {
        display: block;
      }
    }

    p {
      color: #aab2ba;
      font-size: 16px;
      margin-bottom: 30px !important;
    }
  }
  .activition-content {
    h1 {
      color: #1a1b1e;
      font-weight: 600;
      font-size: 29px;
      line-height: 37px;

      span {
        display: block;
      }
    }
    h4 {
      color: ${PrimaryColor};
      margin-bottom: 15px;
      text-align: center;
    }
    p {
      color: #aab2ba;
      font-size: 16px;
      margin-bottom: 30px !important;
    }
  }

  .login-input-fields {
    &-field {
      margin-top: 20px;
    }
  }

  .forgot-content {
    h4 {
      color: ${PrimaryColor};
      margin-bottom: 15px;
    }
    &-activition-detail {
      display: grid;
      grid-template-columns: 2fr 1fr;
      align-items: center;
      margin-bottom: 4px;

      p {
        margin: 0 !important;
        color: #221e30;
        margin-right: 5px !important;
      }

      a {
        margin: 0;
        color: ${PrimaryColor};
        font-size: 15px;
      }
    }
  }

  .activition-input-fields {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    &-field {
      background-color: white;
      border: 2px solid #8d99b247;
      width: 50px;
      height: 50px;
      align-items: center;
      border-radius: 4px;
      text-align: center;
      font-size: 20px;

      &:focus {
        outline: none;
        border: 2px solid ${PrimaryColor} !important;
      }

      ::placeholder {
        text-align: center;
        color: #8d99b247;
        font-weight: 600;
      }
    }
  }
  .activition-handling {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    margin-bottom: 30px;

    p {
      color: #0063ff;
      font-size: 15px;
      margin: 0 !important;
    }

    h6 {
      color: ${PrimaryColor};
      font-size: 15px;
      cursor: pointer;
      margin: 0 !important;
    }
  }
`;
