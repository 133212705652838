import styled from "styled-components";

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* grid-template-rows: 1fr 1fr 1fr; */
  height: 100vh;
  padding: 15px;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;



  .login-input-fields{
    &-field{
      margin: 17px 0;
    }
  }
  .loginScreen-logo{
      margin-left: auto;
      margin-right: auto;

      img{
        width: 150px;
        padding-bottom: 20px;
      }
  }
  .newpassword-content{

    h1{
      font-size: 24px;
      font-weight: 600;
      padding-top: 10px;
    }
    a{
      color: blue;
      text-align: end;
      margin-bottom: 13px !important;
      display: block;
    }
    p{
        color: #AAB2BA;
        font-size: 16px;
        margin-bottom: 10px !important;
    }
  }

  .authScreen-footer{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    p{
      color: gray;
      font-size: 16px;
      margin-bottom: 20px !important;
    }
  }
`;
