import styled from "styled-components";

export const CustomInputContaienr = styled.div`
  width: 100%;
  margin-bottom: 10px;

  label {
    font-size: 13px;
    text-transform: capitalize;
    ////sad
    display: block;
    margin-bottom: 4px;
  }

  .is-invalid {
    border: 1px solid red;
    border-radius: 5px;
    background: white;
    border-radius: 8px;
    padding: 10px;
  }
  .customInput{
    background: white;
    border: 1px solid #c6c6c8;
    border-radius: 8px;
    padding: 10px;
  }
`;
