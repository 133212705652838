import styled from "styled-components";
import {tertiaryDark } from "../../Components/GlobalStyle";

export const DrawerMainStyled = styled.div`
.ant-drawer-body{
padding:0 ;
}
`

export const MobileDrawerContainer = styled.div`
margin-top: 10px;

ul {
padding-left: 0;
margin-top: 20px;

li {
list-style-type: none;
display: flex;
flex-direction: column;
a {
margin: 7px 0;
color: black;
text-decoration: none;
font-size: 18px;
}
}
}

.mobile-nav{

&-profile-section{
margin-left: 20px;
display: flex;
align-items: center;

&-content{
margin-left: 10px;
.designation{
font-weight:700;
font-family:'EnnVisionsBold';
font-size: 16px;
color: ${tertiaryDark};
}
}


p{
margin: 0;
font-weight: 600;
}


img{
width: 60px;
}
}
&-link{
display: flex;
align-items: center;
justify-content: space-between;

margin-top: 5px;
border-bottom: 1px solid #d3d3d369;
&-profile{
display: flex;
align-items: center;
gap: 5px;
padding-left: 10px;
padding-bottom: 5px;

}

p{
margin: 0;
font-size: 17px;
margin-bottom: 2px;
}
img{
width: 20px;
}
}
}
`;
