import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import trainProgram1 from "../../assests/New Images/trainProgram1.png";
import trainProgram2 from "../../assests/New Images/trainProgram2.png";
import trainProgram3 from "../../assests/New Images/trainProgram3.png";
import trainProgram4 from "../../assests/New Images/trainProgram4.png";

import { Container } from 'react-bootstrap';
import { TrainingPrograms } from './styleD';
import { useNavigate } from 'react-router-dom';
const Trainers = () => {
    const navigate=useNavigate();
    const moveToWorkoutList=()=>{
      navigate('/workout-list')
    }


    const heroSliderImages = [
        {
            heroImg: trainProgram3,
            title: "Boxing",
            description: "45 Trainers",
        },
        {
            heroImg: trainProgram1,
            title: "Karate",
            description: "45 Trainers",
        },
        {
            heroImg: trainProgram2,
            title: "Boxing",
            description: "45 Trainers",
        },
        {
            heroImg: trainProgram3,
            title: "Kick Boxing",
            description: "45 Trainers",
        },
        {
            heroImg: trainProgram4,
            title: "Boxing",
            description: "45 Trainers",
        },
        {
            heroImg: trainProgram1,
            title: "Boxing",
            description: "45 Trainers",
        },
        {
            heroImg: trainProgram2,
            title: "Karate",
            description: "45 Trainers",
        },
        {
            heroImg: trainProgram3,
            title: "Kick Boxing",
            description: "45 Trainers",
        },
    ];
    var settings = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: false,

                    dots: false,
                },
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 810,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,

                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },

        ],
    };
    return (
        <TrainingPrograms >
            <Container>
                <h5 className="heading">Training Programs</h5>
                <Slider {...settings} className="trainer-slider-main">
                    {heroSliderImages.map((data, index) => (
                        <div key={index}>
                            <div
                                className="slider-trainer-card"
                                style={{
                                    background: `linear-gradient(to bottom,  transparent , black), url(${data.heroImg})`,
                                }}
                            >
                                <div className="slider-trainer-card" onClick={moveToWorkoutList} style={{ background: `linear-gradient(to bottom,  transparent , black), url(${data.heroImg})` }} >
                                    {/* <img src={img} alt="img" /> */}
                                    <div className="slider-trainer-card-images">
                                        <div className="slider-trainer-card-images-imgtestimonial">

                                            <div className="ms-3 slider-trainer-card-images-imgtestimonial-content">
                                                <h3>{data.title}</h3>
                                                <p className='mt-2'>{data.description}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </Container >

        </TrainingPrograms>

    )
}

export default Trainers










