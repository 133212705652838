import React from 'react'
import backImg from '../../assests/Ninja icons/backFilled.svg';
import saved from '../../assests/Images/saved-fill.svg';
import HomeNavbar from '../home/HomeNavbar';
import Copyrights from '../home/Copyrights';
import { VideoPlayerStyeld } from './style';
import loading from '../../assests/Images/loading.png';
import playerImg from '../../assests/Images/player.png';
import play from '../../assests//Ninja icons/ic_play_icon_with_gradient.svg';
const Index = () => {
    return (
        <VideoPlayerStyeld>
            <HomeNavbar />
            <div className="inner-section">
                <img src={playerImg} className="img-fluid" alt="preview" />
                <div className="video-pause">
                <img src={play} alt="play-img" />
                </div>
                <div className="backward">
                <img src={backImg} alt="saved" />
                </div>
                <div className="video-percentage">
                <img src={loading} alt="loading" />
                </div>
                <div className="video-saved">
                <img src={saved} alt="save" />
                </div>
            </div>
            <div className="copy-rights">
            <Copyrights />
            </div>
        </VideoPlayerStyeld>
    )
}

export default Index