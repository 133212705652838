import React, { useState } from "react";
import { HomePageContainer } from "./style";
import ic_menu from "../../assests/icons/ic_menu.svg";
import ic_gym_logo1 from "../../assests/Images/ic_gym_logo1.png";
import ic_gym_logo2 from "../../assests/Images/ic_gym_logo2.png";
import ic_gym_logo3 from "../../assests/Images/ic_gym_logo3.png";
import ic_gym_logo4 from "../../assests/Images/ic_gym_logo4.png";
import ic_gym_banner1 from "../../assests/Images/ic_gym_banner1.png";
import ic_gym_banner2 from "../../assests/Images/ic_gym_banner2.png";
import ic_gym_banner3 from "../../assests/Images/ic_gym_banner3.png";
import ic_gym_banner4 from "../../assests/Images/ic_gym_banner4.png";
import ic_header_logo from "../../assests/icons/ic_header_logo.svg";
import Slider from "../Slider/Index";
import ic_product_gym1 from "../../assests/Images/ic_product_gym1.png";
import ic_product_gym2 from "../../assests/Images/ic_product_gym2.png";
import ic_product_gym3 from "../../assests/Images/ic_product_gym3.png";
import ic_product_gym4 from "../../assests/Images/ic_product_gym4.png";
import ic_logo_gym1 from "../../assests/Images/ic_logo_gym1.png";
import ic_logo_gym2 from "../../assests/Images/ic_logo_gym2.png";
import ic_maps from "../../assests/icons/ic_maps.svg";
import MobileCanvas from "../mobileCanvas/Index";
import { useNavigate } from "react-router-dom";
import 'react-alice-carousel/lib/alice-carousel.css';
const heroSliderImages = [
  {
    heroImg: ic_product_gym1,
    img: ic_logo_gym1,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
  {
    heroImg: ic_product_gym2,
    img: ic_logo_gym2,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
  {
    heroImg: ic_product_gym3,
    img: ic_logo_gym1,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
  {
    heroImg: ic_product_gym4,
    img: ic_logo_gym2,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
  {
    heroImg: ic_product_gym1,
    img: ic_logo_gym1,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
  {
    heroImg: ic_product_gym2,
    img: ic_logo_gym2,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
  {
    heroImg: ic_product_gym3,
    img: ic_logo_gym1,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
  {
    heroImg: ic_product_gym4,
    img: ic_logo_gym2,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
];

const products = [
  ic_gym_logo1,
  ic_gym_logo2,
  ic_gym_logo3,
  ic_gym_logo4,
  ic_gym_logo1,
  ic_gym_logo2,
  ic_gym_logo3,
  ic_gym_logo4,
  ic_gym_logo1,
  ic_gym_logo2,
  ic_gym_logo3,
  ic_gym_logo4,
];

function Home() {
  const navigate = useNavigate();

  const moveToWorkoutList = () => {
    navigate('/workout-list')
  }

  const [visible, setVisible] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <MobileCanvas onClose={onClose} visible={visible} setVisible={setVisible} />
      <HomePageContainer>
        <div className="home-screen-header">
          <img onClick={() => setVisible(true)} src={ic_menu} alt="ic_menu" className="mt-3 navigate-img" />
          <img src={ic_header_logo} className='mx-auto' alt="ic_header_logo" />
        </div>
        <div className="home-screen-body">
          <h1>Trainers</h1>

          <div className="home-screen-trainers">
            <Slider sliderImages={heroSliderImages} />
          </div>
          <h1>Ninja Traning Locations</h1>
          <div className="home-screen-locations">
            <Slider sliderImages={products} title="location" />
          </div>
          <h1>Training Programs</h1>
          <div className=" container home-screen-programs">
            <div className="home-screen-programs-program">
              <div
                className="home-screen-programs-program-img" onClick={moveToWorkoutList}
                style={{
                  background: `linear-gradient(to bottom,  transparent , black), url(${ic_gym_banner1})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              >
                <div className="home-screen-programs-program-content">
                  <h1>Boxing</h1>
                  <p>45 Trainers</p>
                </div>
              </div>
              <div
                className="home-screen-programs-program-img" onClick={moveToWorkoutList}
                style={{
                  background: `linear-gradient(to bottom,  transparent , black), url(${ic_gym_banner2})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              >
                <div className="home-screen-programs-program-content">
                  <h1>Boxing</h1>
                  <p>45 Trainers</p>
                </div>
              </div>
              <div
                className="home-screen-programs-program-img" onClick={moveToWorkoutList}
                style={{
                  background: `linear-gradient(to bottom,  transparent , black), url(${ic_gym_banner3})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              >
                <div className="home-screen-programs-program-content">
                  <h1>Boxing</h1>
                  <p>45 Trainers</p>
                </div>
              </div>
              <div
                className="home-screen-programs-program-img" onClick={moveToWorkoutList}
                style={{
                  background: `linear-gradient(to bottom,  transparent , black), url(${ic_gym_banner4})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              >
                <div className="home-screen-programs-program-content">
                  <h1>Boxing</h1>
                  <p>45 Trainers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HomePageContainer>
    </div>
  );
}

export default Home;
