import React, { useEffect } from 'react'
import { ProgramListStyled } from './style';
import password from '../../assests/Ninja icons/lock-filled.svg';
import saved from '../../assests/Images/saved-fill.svg';
import programImg1 from '../../assests/New Images/images (22).png';
import programImg2 from '../../assests/New Images/images (11).png';
import { Col, Container, Row } from 'react-bootstrap';
import HomeNavbar from '../home/HomeNavbar';
import Copyrights from '../home/Copyrights';
import { useNavigate } from 'react-router-dom';
import MobileNav from '../MobileNav/Index';
import loading from '../../assests/Images/loading.png';


const Index = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }, 0);
  }, []);
  const programImgs = [programImg1, programImg2, programImg1, programImg1, programImg2, programImg1, programImg1, programImg2, programImg1, programImg1, programImg2, programImg1,]
  const moveBack = () => {
    navigate(-1);
  }

  return (
    <ProgramListStyled>
      <div className="d-none d-sm-block">
        <HomeNavbar />
      </div>
      <div className="d-sm-none">
        <MobileNav />
      </div>
      <Container>
        {/* <div className="back-section d-flex align-items-center d-md-none" onClick={moveBack}>
        <img src={back} alt="back" />
        <p className="back-text ms-2">Back</p>
      </div> */}
        <div className="product-cards">
          <Row className='justify-content-center'>
            {
              programImgs.map((img, index) => (
                <Col sm={6} md={4} key={index} onClick={moveBack}>
                  <div className="list-card mt-3">
                    <img className='main-img' src={img} alt="program" />
                    <img className='feature-img feature-img1 ' src={saved} alt="save" />
                    {/* <img className='feature-img feature-img2 ' src={password} alt="lock" /> */}
                    <img src={loading} alt="loading" className='loading-img' />
                    <div className="content-section">
                      <div className="price-section d-flex justify-content-between">
                        <h4 className="title">Course Name</h4>
                        <h4 className="title">Active</h4>
                      </div>
                      <div className="name-section d-flex justify-content-between">
                        <p className="sub-title">Kick Boxing</p>
                        <p className="sub-title">5 Weeks - 3Days</p>
                      </div>
                    </div>
                  </div>
                </Col>
              ))
            }

          </Row>
        </div>
      </Container>
      <div className="d-none d-sm-block">
        <Copyrights />
      </div>
    </ProgramListStyled>
  )
}

export default Index