import React from 'react'
import { ImagePreviewStyled } from './style'
import previewImg from '../../assests/New Images/imagePreview.png';
import backImg from '../../assests/Ninja icons/backFilled.svg';
import saved from '../../assests/Images/saved-fill.svg';
import play from '../../assests//Ninja icons/ic_play_icon_with_gradient.svg';
import HomeNavbar from '../home/HomeNavbar';
import Copyrights from '../home/Copyrights';
import { useLocation, useNavigate } from 'react-router-dom';

const Index = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const moveBack = () => {
        navigate(-1);
    }

    return (
        <ImagePreviewStyled>
            <div className="d-none d-sm-block">
                <HomeNavbar />
            </div>
            <div className="hero-image">
                <img src={previewImg} className="img-fluid" alt="preview" />
                <div className="backward d-md-none">
                    <img onClick={moveBack} src={backImg} alt="saved" />
                </div>
                {
                    location?.state?.title === 'video' && (
                        <div className="video-pause ">
                            <img src={play} alt="play-img" />
                        </div>
                    )
                }
                <div className="video-saved">
                    <img src={saved} alt="save" />
                </div>
            </div>
            <div className="d-none d-sm-block copyright-section">
                <Copyrights />
            </div>
        </ImagePreviewStyled>

    )
}

export default Index