import React, {useState } from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import About from './About'
import Program from './Program'
import { MyTabsStyled } from './style'

const MyTabs = () => {
  const [isChange, setIsChange] = useState(false);


  return (
    <MyTabsStyled>
      <Container>
        <Tab.Container id="left-tabs-example" onSelect={() => setIsChange(!isChange)} defaultActiveKey="about">
          <Row>
            <Nav variant="pills" className="flex-row">
              <Nav.Item className='nav-item-styled'>
                <Nav.Link eventKey="about">About</Nav.Link>
              </Nav.Item>
              <Nav.Item className='nav-item-styled'>
                <Nav.Link eventKey="program">Program</Nav.Link>
              </Nav.Item>
            </Nav>
            <Col>
              <Tab.Content>
                <Tab.Pane eventKey="about">
                  <About />
                </Tab.Pane>
                <Tab.Pane eventKey="program">
                  <Program change={isChange} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </MyTabsStyled>
  )
}

export default MyTabs