import styled from 'styled-components';
export const HomePageContainer = styled.div`

h1{
  font-size: 16px;
  color: black;
  margin: 0;
  font-weight: 600;
  padding: 0 10px;
  margin: 15px 0;
}

.home-screen-header{
display: flex;
align-items: center;
padding: 10px;
box-shadow: 0px 0px 20px #455B6314;
height: 100px;
.navigate-img{
margin-left: 8px;  
}
/* box-shadow: -3px 2px 18px -14px rgba(0,0,0,0.73);
-webkit-box-shadow: -3px 2px 18px -14px rgba(0,0,0,0.73);
-moz-box-shadow: -3px 2px 18px -14px rgba(0,0,0,0.73); */
}

.home-screen-programs{
width: 100%;



&-program{
width: 100%;
display: flex;
align-items: center;
flex-wrap: wrap;
margin: 15px 0;
gap: 15px;
padding-left: 13px;
display: grid;
grid-template-columns: repeat(2,1fr);
&-img{

height: 170px;
background-position: center;
background-size: cover;
background-repeat: no-repeat;
border-radius: 10px;
}

&-content{
display: flex;
flex-direction: column;
justify-content: flex-end;
height: 100%;
padding: 12px;
h1{
margin: 0;
color: white;
font-size: 14px;
padding: 0;
}
p{
margin: 0;
color: white;
font-size: 12px;
}
      }
    }
 
}

`