import styled from "styled-components";
import { secondaryDark } from "../../Components/GlobalStyle";

export const LoginContainer = styled.div`

padding: 15px;
max-width: 380px;
margin: 0 auto;
background-color: white !important;
.inputs-section{
height: 55vh;
.login-input-fields {
&-field {
margin: 17px 0;
}
}
.loginScreen-logo {
margin-left: auto;
margin-right: auto;
text-align: center;

img {
width: 150px;
padding-bottom: 20px;
}
}
.loginScreen-content {
h1 {
font-size: 24px;
font-weight: 600;
padding: 10px 0;
font-family: "EnnVisionsMedium" !important;
}
a {
color: "#0063FFCC";
text-align: end;
margin-bottom: 13px !important;
display: block;
}
}
}
.authScreen-footer {
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: flex-end;


p {
color: gray;
font-size: 17px;
margin-bottom: 20px !important;
cursor: pointer;
position: absolute;
bottom: 5%;
color: ${secondaryDark};
}
}
`;
