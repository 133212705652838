import styled from 'styled-components';

export const GymJonesStyled = styled.div`
.image{
position: relative;
@media screen and (max-width:550px) {
&>.img-fluid{
height:50vh;
width: 100vw;
object-fit: cover;
}
}
}

.video-pause{
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
img{
width:100px;
max-width: 75%;
}
}
.backward{
position: absolute;
top: 10%;
left: 10%;
}
.video-percentage{
position: absolute;
bottom: 14%;
left: 90%;
img{
width: 85%;
}
@media screen and (max-width:480px) {
bottom: 13%;
left: 82%;
img{
width: 50px;
}
}
}
.video-percentage 
.video-saved{
position: absolute;
top: 10%;
left: 85%;
}
.profile-section{
position: absolute;
bottom: 14%;
left: 5%;

.text-section{
margin-top: 5px;
h4{
font-size: 28px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;
}    
h6{
font-size: 25px;
font-weight: 500;
line-height: 15px;
letter-spacing: 0em;
color: #FFFFFF;
margin-top: 20px;
}
}
@media screen and (max-width:480px) {
img{
height:40px;
}
.text-section{
margin-top: 5px;
h4{
font-size: 20px;
line-height: 16px;
}    
h6{
font-size: 19px;
line-height: 12px;
}
}    
}
}
.profile{
font-size: 28px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
}
.button{
font-size: 14px;
font-weight: 500;
line-height: 9px;
letter-spacing: 0em;
text-align: left;

}
.navigation{
display: flex;
justify-content: space-between;
align-items: center;
font-size: 18px;
font-weight: 500;
line-height: 12px;
letter-spacing: 0em;
text-align: left;

}
.navigation ul{
display: flex;
align-items: flex-end;
position: relative;
top: 16px;
}
.navigation ul li{
list-style: none;
}
.navigation ul li a {
text-decoration: none;
color: black;
}
.navigation button{
color: white;
}
.heading{
font-size: 24px;
font-weight: 700;
line-height: 29px;
letter-spacing: 0em;
text-align: left;
}
.btn-bg{
background-color:  #C7112B;
}
.bg-blue{
background: #0063FF;
}

`

export const MyTabsStyled = styled.div`
@media screen and (max-width:991px) {
margin-top: 20px;    
}
@media screen and (max-width:480px) {
.gym-jones-tabs{
padding: 0;
&-item{
width: 50%;
text-align: center;
&:last-child{
margin-left:0px !important;

}
}
}       
}

.nav{
border-bottom: 1px solid #8D99B2; 
.nav-item:last-child{
margin-left:50px;
}   
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
color: #1A1B1E;    
background:transparent;
border-bottom:3px solid #C7112B;
border-radius: 0;
}
.nav-link{
font-size: 18px;
font-weight: 500;
line-height: 12px;
letter-spacing: 0em;
color: #1A1B1E;
}
`

export const DetailsGymJonesStyled = styled.div`
margin-top: 30px;

.details-content{
.heading{
color: #000000;
font-size: 24px;
font-weight: 700;
line-height: 29px;
}
.sub-heading{
color: #4D4D4D;
font-size: 16px;
font-weight: 400;
line-height: 24px;
}
@media screen and (max-width:480px) {
.heading{
font-size: 17px;
} 
.sub-heading{
font-size: 15px;

}   
}
}

`
export const SessionStyledMain = styled.div`
margin-top: 30px;
position: relative;

.slide-img{
width:40px;
height:40px;  
cursor:pointer;
}
.prev-slide-img{
position: absolute;
left:5%;
top:37%;
z-index: 30;

}
.next-slide-img{
position: absolute;
right:3%;
top:37%;
z-index: 30;

}

.heading{
font-family:'EnnVisionsBold';
font-size: 23px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
color: #1A1B1E;
}
.trainer-slider-main{
margin-top: 20px;
.slider-trainer-card {
height: 180px;
border-radius: 10px;
margin-right: 20px;

background-repeat: no-repeat !important;
background-position: center !important;
background-size: cover !important;


&-images {
display: flex;
justify-content: space-between;
align-items: flex-end;
height: 100%;

padding-bottom: 10px;

&-imgtestimonial {
display: flex;
align-items: center;

&-first {
img {
padding: 0 !important;
}
}
&-second {
img {
padding: 0 !important;
}
}

&-content {
h3 {
margin: 0;
color: white;
font-size: 17px;
font-weight: 700;
line-height: 12px;
letter-spacing: 0em;

}
p {
margin: 0;
color: white;
font-size: 12px;
font-weight: 500;
line-height: 15px;
letter-spacing: 0em;

}
}
}
}
}

}

`
