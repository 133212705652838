import React from 'react'
import trainer1 from "../../assests/New Images/trainer1.png";
import trainer2 from "../../assests/New Images/trainer2.png";
import trainer3 from "../../assests/New Images/trainer3.png";
import trainer4 from "../../assests/New Images/trainer4.png";
import ic_logo_gym1 from "../../assests/Images/ic_logo_gym1.png";
import ic_logo_gym2 from "../../assests/Images/ic_logo_gym2.png";
import maps from "../../assests/icons/ic_maps.svg";
import ic_maps from "../../assests/icons/ic_maps.svg";
import { useNavigate } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import { WorkoutWithToolsStyled } from './style';
const Trainers = () => {
    const navigate = useNavigate();
    const moveToProgramList = () => {
        navigate('/program-List')
    }

    const workoutWithToolsData = [
        {
            heroImg: trainer1,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Jacked Street",
            description: "Kick Boxing",
        },
        {
            heroImg: trainer2,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Scarlett Johansson",
            description: "Taekwondo",
        },
        {
            heroImg: trainer3,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Jacked Street",
            description: "Kick Boxing",
        },
        {
            heroImg: trainer3,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Scarlett",
            description: "Taekwondo",
        },
        {
            heroImg: trainer4,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Jacked Street",
            description: "Kick Boxing",
        },
        {
            heroImg: trainer1,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Jacked Street",
            description: "Kick Boxing",
        },
        {
            heroImg: trainer2,
            img: ic_logo_gym1,
            logo: ic_maps,
            title: "Scarlett Johansson",
            description: "Taekwondo",
        },
        {
            heroImg: trainer3,
            img: ic_logo_gym2,
            logo: ic_maps,
            title: "Scarlett",
            description: "Taekwondo",
        },
    ];

    const workoutWithToolsItems = [
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${workoutWithToolsData[0].heroImg})`,
            }}
        >
            <div onClick={moveToProgramList} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${workoutWithToolsData[0].heroImg})` }} >

                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-first">
                            <img src={workoutWithToolsData[0].img} alt="ic_logo_gym4" />
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial-content">
                            <h3>{workoutWithToolsData[0].title}</h3>
                            <p className='mt-2'>{workoutWithToolsData[0].description}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>,
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${workoutWithToolsData[1].heroImg})`,
            }}
        >
            <div onClick={moveToProgramList} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${workoutWithToolsData[1].heroImg})` }} >

                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-first">
                            <img src={workoutWithToolsData[1].img} alt="ic_logo_gym4" />
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial-content">
                            <h3>{workoutWithToolsData[1].title}</h3>
                            <p className='mt-2'>{workoutWithToolsData[1].description}</p>
                        </div>

                    </div>


                </div>
            </div>
        </div>,
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${workoutWithToolsData[2].heroImg})`,
            }}
        >
            <div onClick={moveToProgramList} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${workoutWithToolsData[2].heroImg})` }} >

                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-first">
                            <img src={workoutWithToolsData[2].img} alt="ic_logo_gym4" />
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial-content">
                            <h3>{workoutWithToolsData[2].title}</h3>
                            <p className='mt-2'>{workoutWithToolsData[2].description}</p>
                        </div>

                    </div>


                </div>
            </div>
        </div>,
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${workoutWithToolsData[0].heroImg})`,
            }}
        >
            <div onClick={moveToProgramList} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${workoutWithToolsData[0].heroImg})` }} >

                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-first">
                            <img src={workoutWithToolsData[0].img} alt="ic_logo_gym4" />
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial-content">
                            <h3>{workoutWithToolsData[0].title}</h3>
                            <p className='mt-2'>{workoutWithToolsData[0].description}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>,
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${workoutWithToolsData[1].heroImg})`,
            }}
        >
            <div onClick={moveToProgramList} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${workoutWithToolsData[1].heroImg})` }} >

                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-first">
                            <img src={workoutWithToolsData[1].img} alt="ic_logo_gym4" />
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial-content">
                            <h3>{workoutWithToolsData[1].title}</h3>
                            <p className='mt-2'>{workoutWithToolsData[1].description}</p>
                        </div>

                    </div>


                </div>
            </div>
        </div>,
        <div
            className="slider-trainer-card"
            style={{
                background: `linear-gradient(to bottom,  transparent , black), url(${workoutWithToolsData[2].heroImg})`,
            }}
        >
            <div onClick={moveToProgramList} className="slider-trainer-card" style={{ background: `linear-gradient(to bottom,  transparent , black), url(${workoutWithToolsData[2].heroImg})` }} >

                <div className="slider-trainer-card-images">
                    <div className="slider-trainer-card-images-imgtestimonial">
                        <div className="slider-trainer-card-images-imgtestimonial-first">
                            <img src={workoutWithToolsData[2].img} alt="ic_logo_gym4" />
                        </div>
                        <div className="slider-trainer-card-images-imgtestimonial-content">
                            <h3>{workoutWithToolsData[2].title}</h3>
                            <p className='mt-2'>{workoutWithToolsData[2].description}</p>
                        </div>

                    </div>


                </div>
            </div>
        </div>

    ];

    return (
        <WorkoutWithToolsStyled >
            <div className="d-flex justify-content-between first-section align-items-center flex-wrap">
                <h5 className="heading text-start">Workout With
                    Tools</h5>
                <div className="link-view">View All</div>
            </div>
            <div className="trainer-slider-main">
                {
                    workoutWithToolsItems.map((data, index) => (
                        data
                    ))
                }

            </div>
        </WorkoutWithToolsStyled>
    )
}

export default Trainers










