import React from "react";
import { Container } from "react-bootstrap";
import { SliderContainer } from "./style";
import box2 from "../../assests/assets/box2.png";
import Slider from "react-slick";
import ic_product_gym1 from "../../assests/Images/offerPerson.png";
import ic_logo_gym1 from "../../assests/Images/ic_logo_gym1.png";
import ic_logo_gym2 from "../../assests/Images/ic_logo_gym2.png";
import ic_maps from "../../assests/icons/ic_maps.svg";

const heroSliderImages = [
  {
    heroImg: ic_product_gym1,
    img: ic_logo_gym1,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
  {
    heroImg: ic_product_gym1,
    img: ic_logo_gym2,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
  {
    heroImg: ic_product_gym1,
    img: ic_logo_gym1,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
  {
    heroImg: ic_product_gym1,
    img: ic_logo_gym2,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
  {
    heroImg: ic_product_gym1,
    img: ic_logo_gym1,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
  {
    heroImg: ic_product_gym1,
    img: ic_logo_gym2,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
  {
    heroImg: ic_product_gym1,
    img: ic_logo_gym1,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
  {
    heroImg: ic_product_gym1,
    img: ic_logo_gym2,
    logo: ic_maps,
    title: "Jacked Street",
    description: "Kick Boxing",
  },
];
const Trainers = () => {
  var settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,

          dots: false,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 810,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow:  1,
          slidesToScroll: 1,
        
        },
      },
    ],
  };
  return (
    <SliderContainer>
      <Container>
        <div className="tranier-slider-main">
          <div className="trainer-person">
            <img src={box2} alt="box2" />
          </div>
          <div className="tranier-slider-main-content-detail" >
          <p>ABOUT TRAINERS</p>
        
            <h1>Trainers</h1>
            <hr/>
            <div className="trainer-persons-slider-main">
           
           <Slider {...settings}>
             {heroSliderImages.map((img, index) => (
               <div key={index}>
                 <div
                   className="slider-trainer-card"
                   style={{
                     background: `linear-gradient(to bottom,  transparent , black), url(${img.heroImg})`,
                   }}
                 >
                  <div className="slider-trainer-card"  style={{background: `linear-gradient(to bottom,  transparent , black), url(${img.heroImg})`}} >
                {/* <img src={img} alt="img" /> */}
                <div className="slider-trainer-card-images">
                  <div className="slider-trainer-card-images-imgtestimonial">
                    <div className="slider-trainer-card-images-imgtestimonial-first">
                      <img src={img.img} alt="ic_logo_gym4" />
                    </div>
                    <div className="slider-trainer-card-images-imgtestimonial-content">
                      <h3>Jacked Street</h3>
                      <p>Kick Boxing</p>
                    </div>
                  </div>
                  <div className="slider-trainer-card-images-imgtestimonial-second">
                    <img src={img.logo} alt="ic_logo_gym4" />
                  </div>
                </div>
              </div>
                 </div>
               </div>
             ))}
           </Slider>
         </div>
          </div>
       
        </div>
      </Container>
    </SliderContainer>
  );
};

export default Trainers;
