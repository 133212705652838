import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "antd";
import { LoginContainer } from "./styled";
import ic_user from "../../assests/icons/ic_user.svg";
import ic_password from "../../assests/icons/ic_password.svg";
import FormControl from "../../Components/FormControl";
import CustomButton from "../../Components/CustomButton/Index";
import { PrimaryColor } from "../../Components/GlobalStyle";
import { Link, useNavigate } from "react-router-dom";
import ic_back from "../../assests/icons/ic_back.svg";
import GenericService from "../../Services/GenericService";
import { API_URL } from "../../Services/config";
import { toast } from "react-toastify";

const initialValues = {
  newPassword: "",
  password: "",
};
const validationSchema = Yup.object({
  newPassword: Yup.string().oneOf([Yup.ref(' password'), null], 'Passwords don\'t match').required("Confirm password is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Minimum six character is required"),
});

const Index = () => {
  const navigation = useNavigate();
  const genericService = new GenericService();


  const username = localStorage.getItem("userData");

  const userData = username && JSON.parse(username);



  const nextScreenHandler = () => {
    navigation("/signup");
  };


  const onSubmit = (value) => {
    const data = {
      password : value.password,
      email : userData.email
    }
    genericService
      .post(`${API_URL}setpassword`, data)
      .then((msg) => {
        if(msg.resultCode == 200 ){
          toast(msg.message , 'top-right')
          navigation("/login");
        }
        else if(msg.resultCode != 200){
          toast(msg.message , 'top-right')
        }
        console.log(msg);
      })
      .catch((error) => {
        console.log(error, "error");
        toast(error.response.data.message , 'top-right')
      });

  };

  return (
    <LoginContainer>
      <div className="newpassword-arrow">
        <img onClick={() => nextScreenHandler()} src={ic_back} alt="ic_back" />
      </div>
  
      <div className="newpassword-content">
        <h1>Set new Password</h1>
        <p>Enter your new password and enjoy Ninija app services</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form
                name="basic"
                onFinish={formik.handleSubmit}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                validateMessages={validationSchema}
              >
                <div className="login-input-fields">
          
                  <div className="login-input-fields-field">
                    <FormControl
                      control="password"
                      type="text"
                      name="password"
                      placeholder="Password"
                      className={
                        formik.errors.password && formik.touched.password
                          ? "is-invalid"
                          : "customInput"
                      }
                      prefix={<img src={ic_password} alt="ic_password" />}
                    />
                  </div>
                  <div className="login-input-fields-field" >
                    <FormControl
                      control="password"
                      type="text"
                      name="newPassword"
                      placeholder="Confirm Password"
                      className={
                        formik.errors.newPassword && formik.touched.newPassword
                          ? "is-invalid"
                          : "customInput"
                      }
                      prefix={<img src={ic_password} alt="ic_password" />}
                    />
                  </div>
                  <CustomButton
                    bgcolor={PrimaryColor}
                    color="white"
                    padding="11px 8px"
                    width="100%"
                    type="submit"
                    title="Submit"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

    </LoginContainer>
  );
};

export default Index;
