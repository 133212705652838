import React, { useState } from "react";
import { ForgotContainer } from "./style";
import CustomButton from "../../Components/CustomButton/Index";
import { PrimaryColor } from "../../Components/GlobalStyle";
import { useNavigate, useParams } from "react-router-dom";
import ic_back from "../../assests/icons/ic_back.svg";
import GenericService from "../../Services/GenericService";
import { API_URL } from "../../Services/config";

const Index = () => {
  const navigation = useNavigate();
  const genericService = new GenericService();

  const params = useParams();

  console.log(params, "name");

  const username = localStorage.getItem("userData");

  const userData = username && JSON.parse(username);

  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [responseMessage, setResponseMessage] = useState("");

  const handleChange = (element, index) => {
    setResponseMessage("");
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const nextScreenHandler = () => {
    navigation("/login");
  };

  const onSubmitHandler = () => {
    const value = {
      otp: otp.toString().replaceAll(",", ""),
      email: userData.email,
    };

    if (otp.includes("")) {
      setResponseMessage(
        "Please enter otp taat is send to your email/ phone number"
      );
    } else {
      genericService
        .post(`${API_URL}verifyotp`, value)
        .then((msg) => {
          if (msg.resultCode == 500 || msg.resultCode == 400) {
            setResponseMessage(msg.message);
          } else if (msg.resultCode == 200) {
            setOtp(new Array(4).fill(""));
            if (params.path == 'forgot') {
              navigation("/new-password");
            }
            else if (params.path == 'new') {
              navigation("/gender");
            }

          }
        })
        .catch((error) => {

          setResponseMessage(error.response.data.message);
        });
    }
  };

  return (
    <div style={{ height: '100vh' }} >
      <ForgotContainer>
        <div className="forgot-arrow">
          <img onClick={() => nextScreenHandler()} src={ic_back} alt="ic_back" />
        </div>

        <div className="activition-content">
          <h1>
            <span>Enter the 4-digit</span>
            <span>code sent to you at</span>
          </h1>
          {params.name == "email" ? (
            <p>{userData.email}</p>
          ) : (
            <p>{userData.phoneNumber}</p>
          )}

          <div>
            <div className="activition-input-fields">
              {otp.map((data, index) => {
                return (
                  <input
                    placeholder="-"
                    className="activition-input-fields-field"
                    type="text"
                    name="otp"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onFocus={(e) => e.target.select()}
                  />
                );
              })}
            </div>
            <h4>{responseMessage}</h4>
            <CustomButton
              bgcolor={PrimaryColor}
              color="white"
              padding="11px 8px"
              width="100%"
              type="button"
              title="Submit"
              clicked={onSubmitHandler}
            />
          </div>
        </div>
      </ForgotContainer>
    </div>
  );
};

export default Index;
