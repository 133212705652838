import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Copyrights from '../home/Copyrights'
import HomeNavbar from '../home/HomeNavbar'
import TrendingWorkout from './TrendingWorkout'
import WorkoutWithoutTools from './WorkoutWithoutTools'
import WorkoutWithTools from './WorkoutWithTools';
import { WorklistMainStyled } from './style';
import MobileNav from '../MobileNav/Index';

const Index = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });

    }, 0);
  }, []);
  return (
    <WorklistMainStyled>
      <div className="d-none d-sm-block">
        <HomeNavbar />
      </div>
      <div className="d-sm-none">
        <MobileNav />
      </div>
      <Container>
        <TrendingWorkout />
        <WorkoutWithoutTools />
        <WorkoutWithTools />
      </Container>
    </WorklistMainStyled>
  )
}

export default Index