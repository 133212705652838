import styled from "styled-components";

export const Container = styled.div`
height: 100vh;
position: relative;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
padding: 15px;
 max-width: 375px;
border-radius: 5px;
background-color: white;

  .loginScreen-content {
    position: absolute;
    width: 90%;
    top: 12%;
    h1 {
      font-size: 21px;
      font-weight: 600;
      padding: 8px 0;
      font-family: "EnnVisionsMedium" !important;
      margin-bottom:0;
    }
    .sub-title{
    color: #AAB2BA;
    font-size: 13px;
    }
  }

.gender-buttons{
margin-top: 40px;
.gender-btn> label {
color:#363636;
font-style: normal;
font-weight: 400;
margin-top: 12px;
font-size: 14px;
color:white;
border-radius: 7px;
text-align: center;
padding:7px 15px;
cursor: pointer;
width:100% ;
}

.gender-btn  input[type="radio"]+label{
border-radius:6px;
border:1px solid #DFE3EA;
font-weight: normal;
color:#221E30;


}
.gender-btn  input[type="radio"]:checked+label { 
background:transparent;
color:#C7112B;
  border:1px solid #C7112B;
}

.gender-btn > input{
display: none;
}
}

.navigate-btn{
    position: absolute;
    width: 90%;
    bottom: 0;
}

`;
