import React, { useRef } from "react";
import { ReviewsFromYourContainer } from "./style";
import reviewsimage from "../../assests/Images/reviews-image.png";
import star from "../../assests/Images/star.png";
import arrowleft from "../../assests/Images/arrow-left.png";
import arrowright from "../../assests/Images/arrow-right.png";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function ReviewsFromYou() {
  const swiper = useSwiper();

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return (
    <ReviewsFromYourContainer>
      <div className="container">
        <div className="Main-div">
          <div className="reviews-content">
            <h5> TESTIMONIAL </h5> <h1> Reviews From You </h1> <hr />
            <Swiper
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={1}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
            >
              <SwiperSlide>
                <h4> Nutritionist </h4> <h3> JEREMY GEORGE </h3>
                <div className="rating">
                  <img src={star} />
                  <img src={star} />
                  <img src={star} />
                  <img src={star} />
                  <img src={star} />
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.At vero eos et accusam et
                  justo duo dolores et ea rebum.Stet clita kasd gubergren, no
                  sea takimata.
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <h4> Nutritionist </h4> <h3> JEREMY GEORGE </h3>
                <div className="rating">
                  <img src={star} />
                  <img src={star} />
                  <img src={star} />
                  <img src={star} />
                  <img src={star} />
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.At vero eos et accusam et
                  justo duo dolores et ea rebum.Stet clita kasd gubergren, no
                  sea takimata.
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <h4> Nutritionist </h4> <h3> JEREMY GEORGE </h3>
                <div className="rating">
                  <img src={star} />
                  <img src={star} />
                  <img src={star} />
                  <img src={star} />
                  <img src={star} />
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.At vero eos et accusam et
                  justo duo dolores et ea rebum.Stet clita kasd gubergren, no
                  sea takimata.
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <h4> Nutritionist </h4> <h3> JEREMY GEORGE </h3>
                <div className="rating">
                  <img src={star} />
                  <img src={star} />
                  <img src={star} />
                  <img src={star} />
                  <img src={star} />
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.At vero eos et accusam et
                  justo duo dolores et ea rebum.Stet clita kasd gubergren, no
                  sea takimata.
                </p>
              </SwiperSlide>
              <div className="navigate-btns">
                <button ref={prevRef} className="navigate-btn" type="button">
                  <img src={arrowleft} />
                </button>
                <button
                  ref={nextRef}
                  className="navigate-btn disabled"
                  type="button"
                >
                  <img src={arrowright} />
                </button>
              </div>
            </Swiper>
          </div>
          <div className="reviews-image">
            <img src={reviewsimage} />
          </div>
        </div>
      </div>
    </ReviewsFromYourContainer>
  );
}

export default ReviewsFromYou;
