import React from 'react'
import { DetailsGymJonesStyled } from './style'

const Details = () => {
    return (
        <DetailsGymJonesStyled>
            <div className="details-content">
                <h5 className='heading'>The Hit Trainer will Improve your back</h5>
                <p className="sub-heading">Yoke Squad is all about getting as jacked as possible, as fast as possible, in the
                    most
                    efficient way possible.We'll focus on proper bio-mechanics to target the specific tissue you're
                    looking
                    to grow and help you understand how to perform the movements in a way that fits your individual
                    structure.</p>
            </div>
            <div className="details-content">
                <h1 className='heading'>Equipment Needed</h1>
                <p className="sub-heading">Yoke Squad is all about getting as jacked as possible, as fast as possible, in the
                    most
                    efficient way possible.We'll focus on proper bio-mechanics to target the specific tissue you're
                    looking
                    to grow and help you understand how to perform the movements in a way that fits your individual
                    structure..</p>
            </div>
        </DetailsGymJonesStyled>
    )
}

export default Details