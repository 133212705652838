import styled from 'styled-components';
import { lightGrey, secondaryGrey, tertiaryDark } from '../../Components/GlobalStyle';


export const PaymentMethodStyled = styled.div`
background-color: white;
.toggle-section{
p{
margin-bottom:0px ;
}    
}
.inner-container{
max-width:730px;
margin:0 auto ;
box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
border-radius: 10px;
padding: 30px;
margin-top: 27px;

.payment-box{
padding:12px;
border: 1.5px solid #D6D6D6;
border-radius: 10px; 
margin-top: 24px;
img{
cursor:pointer;
}
.duration-section{
margin-left: 17px;
.title{
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
color: ${tertiaryDark};
margin: 0;
}
.description{
font-size: 14px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0em;
margin: 0;
color:${lightGrey};
margin-top: 6px;
}    
}
.price{
font-size: 20px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
color: #C7112B;
margin: 0;
}

@media screen and (max-width:480px){
.duration-section{
margin-left: 17px;
.title{
font-size: 14px;
line-height: 18px;
}
.description{
font-size: 14px;
line-height: 17px;
}

}
.price{
font-size: 17px;
line-height: 20px;
}

}
}
.unlock-text{
font-size: 18px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
color: ${secondaryGrey};
margin-top: 30px;
@media screen and (max-width:480px){
font-size: 16px;
line-height: 18px;
}
}
.card-inputs{
margin-top:10px;
.ant-input{
margin-top:20px;
border: 1.5px solid #D6D6D6;
border-radius: 6px;
padding: 13px 11px;
font-family: EnnVisions;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
border:1px solid #8D99B247;
::placeholder{
color:${secondaryGrey};
}
}
}
.complete-order{
margin-top:30px;
button{
background-color:${secondaryGrey};
border:none;
border-radius: 6px;
padding: 12px 100px;
color: #FFFFFF;
text-align: center;
font-family: EnnVisions;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;


}    
}
@media screen and (max-width:480px) {
.complete-order{

button{
background-color:${secondaryGrey};
border:none;
border-radius: 6px;
padding: 16px 75px;
color: #FFFFFF;
text-align: center;
font-family: EnnVisions;
font-size: 15px;
line-height: 14px;
position: absolute;
bottom: 4%;
}    
}
padding: 10px;
box-shadow: unset;

}
@media screen and (max-width:480px){
margin-top:20px;
}
}

.footer-section{
position: absolute;
width: 100%;
bottom: 0;
}

`