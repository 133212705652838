import styled from 'styled-components';

export const VideoPlayerStyeld = styled.div`

.inner-section{
position: relative;
.img-fluid{
height: 79vh !important;   
width :100vw;
@media screen and (max-width:480px){
object-fit: cover;
height: 45vh !important;    
width :100%;

}
}
}
.video-pause{
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
img{
width:100px;
max-width: 75%;
}
}

.backward{
position: absolute;
top: 10%;
left: 10%;
}
.video-percentage{
position: absolute;
bottom: 5%;
left: 90%;
img{
width: 85%;
}
}
.video-saved{
position: absolute;
top: 10%;
left: 85%;
}
.copy-rights{
margin-top: -60px;
}
`
