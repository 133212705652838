import React from "react";
import ReactInputMask from "react-input-mask";
import { CustomInputMaskContainer } from "./style";
import { Field, ErrorMessage } from "formik";
import ErrorMsg from "../ErrorMessage";
import { Input } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { MaskedInput } from "antd-mask-input";

const Index = (props) => {
    const { label, prefix, placeholder, className, name, ...rest } = props;

  return (
    <CustomInputMaskContainer>
       <MaskedInput
              ref={this.inputMaskRef}
              mask="(11) 1 1111-1111"
            />
    </CustomInputMaskContainer>
  );
};

export default Index;
