import styled from "styled-components";

export const SliderContainer = styled.div`
  width: 100%;
  padding: 0 10px;

  .slider-location-images {
    /* box-shadow: 4px 4px 31px -12px rgba(0, 0, 0, 0.73);
    -webkit-box-shadow: 4px 4px 31px -12px rgba(0, 0, 0, 0.73);
    -moz-box-shadow: 4px 4px 31px -12px rgba(0, 0, 0, 0.73); */
    width: max-content;

    img {
      width: 100px;
    }
  }

  .slider-trainer-card {
    height: 150px;
    border-radius: 10px;
    margin-right: 20px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    

    &-images{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      height: 100%;
      padding: 0 10px;
      padding-bottom: 10px;

      &-imgtestimonial{
        display: flex;
        align-items: center;

        &-first{
          img{
            padding: 0 !important;
          }
        }
        &-second{
          img{
            padding: 0 !important;
          }
        }


        &-content{
          h3{
            margin: 0;
            color: white;
            font-size: 13px;
          }
          p{
            margin: 0;
            color: white;
            font-size: 11px;
          }
        }
      }
    }








  }
`;
