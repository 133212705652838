import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { TertiaryHeading } from '../../Components/GlobalStyle'
import { TopTeamStyled } from './style';
import img1 from '../../assests/New Images/images (9).png';
import img2 from '../../assests/New Images/images (21).png';
import img3 from '../../assests/New Images/images (10).png';
import playImg from '../../assests/Ninja icons/ic_play_icon_with_gradient.svg';
import savedfill from '../../assests/Images/saved-fill.svg';
import { useNavigate } from 'react-router-dom';

const TopTeam = () => {
    const navigate = useNavigate();

    const moveToHireMe = () => {
        navigate('/hire-me')
    }
    const moveToImagePreview = () => {
        navigate('/image-preview')
    }
    const topTeamData = [{ img: img1, title: 'Get As Jacked As Possible', sub_title: 'Stop wasting time on training programs and training partners that don\'t demand your best.' }, { img: img2, title: 'Direct Access to Me', sub_title: 'Stop wasting time on training programs and training partners that don\'t demand your best.' }, { img: img3, title: 'Get As Jacked As Possible', sub_title: 'Stop wasting time on training programs and training partners that don\'t demand your best.' },]
    return (
        <TopTeamStyled>
            <TertiaryHeading>
                Brazilian Top Team Long Beach Jiu
            </TertiaryHeading>
            <h5 className="sub-title">
                Yoke Squad is all about getting as jacked as possible, as fast as possible, in the most efficient way possible.We'll focus on proper bio-mechanics to target the specific tissue you're looking to grow and help you understand how to perform the movements in a way that fits your individual structure.
            </h5>
            <div className="d-flex mt-3 w-100 d-sm-none">
                <button className='hire-me-btn w-100 mx-2' onClick={moveToHireMe}>Hire Me / $27 / mo</button>
            </div>
            <div className="cards-section">
                <Row className='justify-content-center'>
                    {topTeamData.map(({ img, title, sub_title }, index) => (
                        <Col sm={6} md={4} key={index} className='mt-2 mt-md-0'>
                            <Card onClick={moveToImagePreview}>
                                <Card.Img variant="top" src={img} />
                                {index === 1 && (
                                    <img className='play-img' src={playImg} alt="play" />
                                )
                                }
                                <img className='save-img' src={savedfill} alt="save" />
                                <Card.Body>
                                    <Card.Title>{title}</Card.Title>
                                    <Card.Text>
                                        {sub_title}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </TopTeamStyled>
    )
}

export default TopTeam