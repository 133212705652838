import styled from 'styled-components';

export const MobileNavStyled=styled.div`
.home-screen-header{
display: flex;
align-items: center;
padding: 10px;
box-shadow: 0px 0px 20px #455B6314;
height: 100px;
}

`